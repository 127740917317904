import React, { useState, useEffect } from "react";
import {
  Badge,
  Button,
  Collapse,
  Image,
  InputNumber,
  Select,
  Tooltip,
} from "antd";
import {
  CreditCardFilled,
  MoneyCollectFilled,
  DollarCircleFilled,
  ShoppingCartOutlined,
} from "@ant-design/icons";
import "./ProductItem.css";
import { toast } from "react-toastify";
import getSymbolFromCurrency from "currency-symbol-map";
import axios from "axios";

const { Option } = Select;

const ProductItem = ({
  product,
  addToCart,
  removeCartItem,
  rules,
  cartItems,
  setCartItems,
  exchangeRate,
  handleCheckout,
}) => {
  const debugMode = true; // Set initial debug mode to false

  const [quantity, setQuantity] = useState(1);
  const [selectedType, setSelectedType] = useState("");
  const [selectedCondition, setSelectedCondition] = useState("NM");
  const [displayedCashPrice, setDisplayedCashPrice] = useState(0);
  const [displayedCreditPrice, setDisplayedCreditPrice] = useState(0);
  const [notification, setNotification] = useState("");
  const [isAddedToCart, setIsAddedToCart] = useState(false);
  const [currentRule, setCurrentRule] = useState([]);
  const [is3Decimal, setIs3Decimal] = useState(false);
  const [isBuyingCash, setIsBuyingCash] = useState(false);
  const [isBuyingCredit, setIsBuyingCredit] = useState(false);

  useEffect(() => {
    const availableConditions = new Set(
      product.skus.map((sku) => sku.condAbbr)
    );
    if (!availableConditions.has(selectedCondition)) {
      setSelectedCondition([...availableConditions][0]);
    }
  }, [product]);

  useEffect(() => {
    const availableTypes = product.skus
      .filter((sku) => sku.condAbbr === selectedCondition)
      .map((sku) => sku.printingName);
    if (!availableTypes.includes(selectedType) && availableTypes.length > 0) {
      setSelectedType(availableTypes[0]);
    }
  }, [selectedCondition, product.skus, selectedType]);

  useEffect(() => {
    updateDisplayedPrices();
  }, [selectedType, selectedCondition, product, rules, exchangeRate]);

  const updateDisplayedPrices = () => {
    const startPrice = calculateStartingPrice();
    console.log(startPrice);
    if (startPrice !== null && !isNaN(startPrice)) {
      // Add this check
      const { cashPrice, creditPrice } = applyPricingRules(
        startPrice,
        selectedCondition.toUpperCase()
      );
      if (!isNaN(cashPrice) && !isNaN(creditPrice)) {
        // Check if cashPrice and creditPrice are not NaN
        setDisplayedCashPrice(parseFloat(cashPrice));
        setDisplayedCreditPrice(parseFloat(creditPrice));
        setIs3Decimal(
          cashPrice % 1 !== 0 &&
            cashPrice.toString().includes(".") &&
            cashPrice.toString().split(".")[1].length > 2
        );
        setNotification("");
      } else {
        setNotification("Not currently buying."); // Set notification if prices are NaN
      }
    } else {
      setDisplayedCashPrice(0);
      setDisplayedCreditPrice(0);
    }
  };

  const calculateStartingPrice = () => {
    const matchingSku = product.skus.find(
      (sku) =>
        sku.condAbbr === selectedCondition &&
        sku.printingName === selectedType &&
        sku.langAbbr === "EN" &&
        sku.pricingInfo
    );
    console.log("MATCHING", matchingSku);

    if (matchingSku) {
      const lowPrice = parseFloat(matchingSku.pricingInfo.lowPrice);
      const marketPrice = parseFloat(matchingSku.pricingInfo.marketPrice);

      if (!isNaN(lowPrice) && !isNaN(marketPrice)) {
        return lowPrice > marketPrice ? marketPrice : lowPrice;
      } else if (!isNaN(lowPrice)) {
        return lowPrice;
      } else if (!isNaN(marketPrice)) {
        return marketPrice;
      }
    }

    return null;
  };

  const applyPricingRules = (startPrice, condition) => {
    console.log("Start Price", startPrice);
    let cashPrice = startPrice * exchangeRate;
    let creditPrice = startPrice * exchangeRate;
    console.log("Start Price Converted", cashPrice, "Rate: " + exchangeRate);

    const nmPrice =
      calculateNMPrice() * exchangeRate || startPrice * exchangeRate;

    const conditionDiscounts = {
      NM: 1.0, // 100% of NM price
      LP: 0.95, // 95% of NM price
      MP: 0.9, // 90% of NM price
      HP: 0.85, // 85% of NM price
      DM: 0.8, // 80% of NM price
    };

    const applicableRules = Object.values(
      rules.storeConfig?.priceConfig || {}
    ).filter(
      (rule) =>
        rule.game === product.categoryId &&
        (!rule.sets ||
          rule.sets.length === 0 ||
          rule.sets.includes(product.groupId))
    );

    console.log("Pricing Configuration", rules.storeConfig?.priceConfig);

    applicableRules.sort((a, b) => {
      return (
        b.sets.includes(product.groupId) - a.sets.includes(product.groupId) ||
        (b.rarities && b.rarities[product.rarity] ? 1 : 0) -
          (a.rarities && a.rarities[product.rarity] ? 1 : 0)
      );
    });

    console.log("Applicable Rules", applicableRules);

    const validRules = applicableRules.filter((rule) => {
      const start = parseFloat(rule.priceRange?.start || 0);
      const end = parseFloat(rule.priceRange?.end || Infinity);
      return (
        start <= startPrice * exchangeRate && startPrice * exchangeRate <= end
      );
    });

    console.log("Valid Rules", validRules);
    console.log(product, selectedType);
    let matchingRule =
      validRules.find(
        (rule) => rule.rarities && rule.rarities[product.rarity]
      ) ||
      validRules[0] ||
      rules.storeConfig?.priceConfig?.globalRule;
    if (selectedType !== "Normal") {
      matchingRule =
        validRules.find(
          (rule) =>
            rule.rarities && rule.rarities[selectedType + " " + product.rarity]
        ) ||
        validRules[0] ||
        rules.storeConfig?.priceConfig?.globalRule;
    } else {
      matchingRule =
        validRules.find(
          (rule) => rule.rarities && rule.rarities[product.rarity]
        ) ||
        validRules[0] ||
        rules.storeConfig?.priceConfig?.globalRule;
    }
    console.log("Matching Rule", matchingRule);

    setCurrentRule(matchingRule);

    if (matchingRule) {
      const increasePercentage = matchingRule.increasePercentage || 0;
      if (increasePercentage !== 0) {
        cashPrice += (cashPrice * increasePercentage) / 100;
        creditPrice += (creditPrice * increasePercentage) / 100;
      }

      let conditionRules =
        matchingRule?.rarities?.[product.rarity]?.[condition] ||
        matchingRule?.rarities?.["All"]?.[condition];
      if (selectedType !== "Normal") {
        conditionRules =
          matchingRule?.rarities?.[selectedType + " " + product.rarity]?.[
            condition
          ] || matchingRule?.rarities?.["All"]?.[condition];
      } else {
        conditionRules =
          matchingRule?.rarities?.[product.rarity]?.[condition] ||
          matchingRule?.rarities?.["All"]?.[condition];
      }
      if (conditionRules) {
        console.log("Condition Rule", conditionRules);

        if (conditionRules.enableCash) {
          setIsBuyingCash(true);
          cashPrice =
            conditionRules.cashBuyrate.toString() === "0"
              ? parseFloat(conditionRules.cashBuyrateBaseline)
              : (cashPrice * parseFloat(conditionRules.cashBuyrate)) / 100;
        } else {
          setIsBuyingCash(false);
        }

        if (conditionRules.enableCredit) {
          setIsBuyingCredit(true);
          creditPrice =
            conditionRules.creditBuyrate.toString() === "0"
              ? parseFloat(conditionRules.creditBuyrateBaseline)
              : (creditPrice * parseFloat(conditionRules.creditBuyrate)) / 100;
        } else {
          setIsBuyingCredit(false);
        }
        if (conditionRules.quantity === undefined) {
          conditionRules.quantity = 0;
        }
        console.log(Number(product.invQuantity), conditionRules.quantity);
        if (
          Number(product.invQuantity) !== 0 &&
          Number(conditionRules.quantity) &&
          Number(product.invQuantity) > Number(conditionRules.quantity)
        ) {
          const discountPercentage = Number(conditionRules.discount || 0);
          if (discountPercentage > 0) {
            const discount = (cashPrice * discountPercentage) / 100;
            console.log(
              `Applying Discount of ${discount} to price ${cashPrice}`
            );
            cashPrice -= discount;
            creditPrice -= discount; // Apply same discount to credit price
            console.log(`New price with discount ${cashPrice}`);
          }
        }
      } else {
        setIsBuyingCash(false);
        setIsBuyingCredit(false);
        setNotification("Not currently buying.");
      }
    } else {
      setIsBuyingCash(false);
      setIsBuyingCredit(false);
      setNotification("Not currently buying.");
    }

    const maxCashPrice = nmPrice * conditionDiscounts[condition];
    const maxCreditPrice = nmPrice * conditionDiscounts[condition];
    cashPrice = Math.min(cashPrice, maxCashPrice);
    creditPrice = Math.min(creditPrice, maxCreditPrice);
    if (
      (condition === "LP" && cashPrice > nmPrice * conditionDiscounts.LP) ||
      (condition === "MP" && cashPrice > nmPrice * conditionDiscounts.MP)
    ) {
      setIsBuyingCash(false);
      setIsBuyingCredit(false);
      setNotification("Not currently buying.");
      return { cashPrice: "0.00", creditPrice: "0.00" };
    }
    if (
      (cashPrice === 0.00) ||
      (creditPrice === 0.00)
    ) {
      setIsBuyingCash(false);
      setIsBuyingCredit(false);
      setNotification("Not currently buying.");
      return { cashPrice: "0.00", creditPrice: "0.00" };
    }
    return {
      cashPrice: Number(cashPrice).toFixed(2),
      creditPrice: Number(creditPrice).toFixed(2),
    };
  };

  const calculateNMPrice = () => {
    const nmSku = product.skus.find(
      (sku) => sku.condAbbr === "NM" && sku.pricingInfo
    );
    return nmSku
      ? parseFloat(nmSku.pricingInfo.lowPrice || nmSku.pricingInfo.marketPrice)
      : null;
  };
  const handleAddToCart = (type, newQuantity) => {
    if (product.invQuantity > rules.buylistConfig?.maximumBuyQuantity) {
      toast.error(
        "This item cannot be added to the cart, We don't need anymore of these right now."
      );
      return;
    }

    const newCartItem = {
      product,
      type,
      quantity: newQuantity,
      selectedType,
      selectedCondition,
      price: type === "cash" ? displayedCashPrice : displayedCreditPrice,
    };

    // Check if an item with the same properties already exists in the cart
    const existingItemIndex = cartItems.findIndex(
      (item) =>
        item.product._id === newCartItem.product._id &&
        item.selectedCondition === newCartItem.selectedCondition &&
        item.selectedType === newCartItem.selectedType &&
        item.type === newCartItem.type
    );

    if (existingItemIndex !== -1) {
      // Merge quantities if the item already exists in the cart
      const updatedCartItems = [...cartItems];
      updatedCartItems[existingItemIndex].quantity += newQuantity;
      setCartItems(updatedCartItems);
    } else {
      // Generate a new _id for the item if it differs in condition or type
      const uniqueCartItem = {
        ...newCartItem,
        product: {
          ...newCartItem.product,
          _id: Math.random().toString(36).substr(2, 9),
        },
      };
      setCartItems([...cartItems, uniqueCartItem]);
    }

    setIsAddedToCart(true);
    toast.info(`${product.name} added to cart!`);
  };

  return (
    <div className={`product-item ${isAddedToCart ? "added-to-cart" : ""}`}>
      <div className="product-image">
        <Image
          src={product.image}
          alt={product.name}
          fallback={"https://app.tcgsync.com/Magic_card_back.webp"}
          placeholder={
            <Image
              src="https://app.tcgsync.com/Magic_card_back.webp"
              alt="Placeholder Image"
              style={{ width: 200 }}
            />
          }
        />
      </div>

      <div className="product-details">
        <p className="product-name">
          <Tooltip title={product.name}>
            {product.name} {product.number ? `(${product.number})` : ""}
          </Tooltip>
        </p>
        <p className="product-expansion">{product.expansionName}</p>
        <p className="product-rarity">{product.rarity}</p>
        {notification && <p className="notification">{notification}</p>}
        <div className="product-condition">
          <Select
            value={selectedCondition}
            onChange={(value) => setSelectedCondition(value)}
            style={{ width: "100%" }}
          >
            {product.skus
              .map((sku) => sku.condAbbr)
              .filter((value, index, self) => self.indexOf(value) === index)
              .sort(
                (a, b) =>
                  ["NM", "LP", "MP", "HP", "DM"].indexOf(a) -
                  ["NM", "LP", "MP", "HP", "DM"].indexOf(b)
              )
              .map((condition) => (
                <Option key={condition} value={condition}>
                  {condition}
                </Option>
              ))}
          </Select>
        </div>
        <div className="product-type">
          <Select
            value={selectedType}
            onChange={(value) => setSelectedType(value)}
            style={{ width: "100%" }}
          >
            {product.skus
              .map((sku) => sku.printingName)
              .filter((value, index, self) => self.indexOf(value) === index)
              .map((printingType) => (
                <Option key={printingType} value={printingType}>
                  {printingType}
                </Option>
              ))}
          </Select>
        </div>
        <div className="product-price">
          <InputNumber
            min={1}
            max={
              rules.buylistConfig?.maximumBuyQuantity
                ? rules.buylistConfig?.maximumBuyQuantity
                : 10 - product.invQuantity
            }
            value={quantity}
            style={{ width: "100%" }}
            onChange={(value) => setQuantity(value)}
          />
          <Button
            onClick={() => handleAddToCart("cash", quantity)}
            style={{
              margin: "10px 0",
              width: "100%",
              color: "white",
              backgroundColor:
                displayedCashPrice > 0 && isBuyingCash
                  ? rules.storeConfig.cashButtonColor
                  : rules.storeConfig.cartButtonColor,
            }}
            disabled={!isBuyingCash || displayedCashPrice <= 0}
          >
            {isBuyingCash
              ? `Cash: $${displayedCashPrice}`
              : rules.storeConfig.notBuyingLabel}
          </Button>

          <Button
            onClick={() => handleAddToCart("credit", quantity)}
            style={{
              margin: "10px 0",
              width: "100%",
              color: "white",
              backgroundColor:
                displayedCreditPrice > 0 && isBuyingCredit
                  ? rules.storeConfig.creditButtonColor
                  : rules.storeConfig.cartButtonColor,
            }}
            disabled={!isBuyingCredit || displayedCreditPrice <= 0}
          >
            {isBuyingCredit
              ? `Credit: $${displayedCreditPrice}`
              : rules.storeConfig.notBuyingLabel}
          </Button>

          <Button
            icon={<ShoppingCartOutlined />}
            style={{
              margin: "10px 0",
              width: "100%",
              color: "white",
              backgroundColor: rules.storeConfig.cartButtonColor,
            }}
            type="primary"
            onClick={handleCheckout}
          >
            View Cart
          </Button>
          {/* {currentRule && (
            <div className="current-rule">
              <h3 className="current-rule-title">Current Pricing Rule</h3>
              <div className="current-rule-details">
                <div className="rule-section">
                  <h4>Sets:</h4>
                  <p>{currentRule.sets && currentRule.sets.join(', ')}</p>
                </div>
                <div className="rule-section">
                  <h4>Rarities:</h4>
                  <p>{currentRule.rarities && Object.keys(currentRule.rarities).join(', ')}</p>
                </div>
                <div className="rule-section">
                  <h4>Price Range:</h4>
                  <p>{currentRule.priceRange && currentRule.priceRange.start} - {currentRule.priceRange && currentRule.priceRange.end}</p>
                </div>
                <div className="rule-section">
                  <h4>Increase Percentage:</h4>
                  <p>{currentRule.increasePercentage ? `${currentRule.increasePercentage}%` : '0%'}</p>
                </div>
                {currentRule.rarities && (
                  <div className="rarity-rules">
                    <h4>Rarity-specific Rules:</h4>
                    {Object.keys(currentRule.rarities).map(rarity => (
                      <div key={rarity}>
                        <h5 className="rarity-title">Rarity: {rarity}</h5>
                        <ul className="condition-list">
                          {Object.keys(currentRule.rarities[rarity]).map(condition => (
                            <li key={condition} className="condition-item">
                              <h6>{condition}:</h6>
                              <ul className="sub-rule-list">
                                {Object.entries(currentRule.rarities[rarity][condition]).map(([key, value]) => (
                                  <li key={key} className="sub-rule-item">
                                    <span className="sub-rule-key">{key}:</span> {JSON.stringify(value)}
                                  </li>
                                ))}
                              </ul>
                            </li>
                          ))}
                        </ul>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          )} */}
        </div>
      </div>
    </div>
  );
};

export default ProductItem;
