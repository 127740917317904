import React, { useState } from 'react';
import { Modal, Button, Input, message, List } from 'antd';
import { ShoppingCartOutlined, DollarCircleOutlined } from '@ant-design/icons';
import { nameNumberSearch } from "../handler/APIService";
import './DeckModal.css';
import { toast } from 'react-toastify';

const { TextArea } = Input;

const Decklist = ({ visible, onCancel }) => {
  const [decklist, setDecklist] = useState('');
  const [csvData, setCsvData] = useState([]);
  const [uploading, setUploading] = useState(false);

  const parseDecklist = (decklist) => {
    return decklist.split('\n').filter(line => line.trim() !== '').map(line => {
      const parts = line.split(' - ');
      if (parts.length === 3) {
        const name = parts[0].trim();
        const set = parts[1].trim();
        const number = parts[2].trim();
        return { name, set, number };
      }
      message.error(`Invalid format: ${line}`);
      return null;
    }).filter(item => item !== null);
  };

  const fetchProductMatches = async (data) => {
    setUploading(true);
    const promises = data.map(async ({ name, set, number }) => {
      try {
        const response = await nameNumberSearch(encodeURIComponent(name), encodeURIComponent(number), encodeURIComponent(set));
        console.log(response)
        if (response) {
          return {
            productId: response.productId,
            condition: "NM",
            name: response.name,
            number: response.number,
            set: response.abbreviation,
            match: response,
            price: null,
            quantity: 1,
            ...response,
          };
        }
      } catch (error) {
        console.error("Error fetching product match:", error);
      }
      return { name: `${name} (NO MATCH)`, number, set };
    });

    const updatedData = await Promise.all(promises);
    setCsvData(updatedData);
    setUploading(false);
  };

  const handleTriggerProcess = async (actionType) => {
    const parsedData = parseDecklist(decklist);
    console.log("Data", parsedData)
    if (parsedData.length > 0) {
      const matchedProducts = await fetchProductMatches(parsedData);
      console.log(matchedProducts); // Process fetched data
    }
  };

  



  const ProductItem = ({ product }) => (
    <List.Item>
      <List.Item.Meta
        avatar={<img src={product.image} alt={product.name} style={{ width: '50px' }} />}
        title={product.name}
        description={`Set: ${product.abbreviation}, Number: ${product.number}, Trend Price: ${product.usPrices?.[0]?.marketPrice || 'N/A'}`}
      />

     
    </List.Item>
  );

  return (
    <Modal
      title={null}
      visible={true }
      onCancel={onCancel}
      footer={null}
      width={600}
      className="deck-modal"
    >
      <div className="deck-modal-header">
        <img src={"https://1000logos.net/wp-content/uploads/2022/10/Magic-The-Gathering-logo.png"} alt="Magic: The Gathering" />
        <h1>I WOULD LIKE TO</h1>
      </div>
      <div className="deck-modal-buttons-container">
        <div className="deck-modal-button-with-label">
          <Button
            className="deck-modal-buy-button circle-button"

            onClick={() => handleTriggerProcess('buy')}

          >
            <div className="button-label">BUY CARDS</div>
          </Button>
        </div>
        <div className="deck-modal-button-with-label">
          <Button
            className="deck-modal-sell-button circle-button"

            onClick={() => handleTriggerProcess('sell')}

          >
            <div className="button-label">SELL CARDS</div>
          </Button>
        </div>
      </div>

     
      {csvData.length > 0 && (
        <List
          itemLayout="horizontal"
          dataSource={csvData}
          renderItem={item => (
            <ProductItem product={item} />
          )}
        />
      )}
      <div className="deck-modal-text-container">
        <TextArea
          rows={4}
          value={decklist}
          onChange={(e) => setDecklist(e.target.value)}
          placeholder="Paste your decklist here using Provided format"
          className="deck-modal-textarea"
        />
        <div className="example-decklist">
          Example Format
          <br />
          Fire Elemental - S99 - 96
          <br />
          Fire Tempest - S99 - 97
          <br />
          Blink - WHO - 116
          <br />
          Weeping Angel - WHO - 168
          <br />
          Card Name - Set Code - Card Number
          <br />
        </div>

      </div>
    </Modal>
  );
};

export default Decklist;
