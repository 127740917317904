import React, { useState } from "react";
import { Modal, Button, message, Table, InputNumber, Input } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { useCSVReader } from 'react-papaparse';
import axios from 'axios';
import { nameNumberSearch } from "../handler/APIService";
import { toast } from "react-toastify";

const CSVModal = ({ visible, onCancel, cartItems, setCartItems }) => {
  const [csvData, setCsvData] = useState([]);
  const [uploading, setUploading] = useState(false);

  const { CSVReader } = useCSVReader();

  const handleUploadAccepted = async (results) => {
    if (results && results.data && results.data.length > 1) {
      const parsedData = results.data.slice(1);
      setUploading(true);
      const updatedData = await fetchProductMatches(parsedData);
      setCsvData(updatedData);
      setUploading(false);
    } else {
      message.error("Invalid CSV file. Please upload a valid CSV file.");
    }
  };

  const fetchProductMatches = async (data) => {
    const promises = data.map(async (item) => {
      const [name, number, set] = item;
      const productPromise = getProductMatch(encodeURIComponent(name), encodeURIComponent(number), encodeURIComponent(set));
      return productPromise.then((product) => {
        if (product) {
          return {
            productId: product?.productId,
            quantity: 1,
            condition: "NM",
            name: product.name,
            number: product.number,
            set: product.abbreviation,
            match: product,
            price: null,
            ...product,
          };
        } else {
          return { name: name + " (NO MATCH)", number };
        }
      });
    });
  
    const updatedData = await Promise.all(promises);
    return updatedData;
  };
  

  const getProductMatch = async (name, number, set) => {
    try {
      const response = await nameNumberSearch(name, number, set);
      return response;
    } catch (error) {
      console.error("Error fetching product match:", error);
      return null;
    }
  };

  const clearData = () => {
    setCsvData([]);
  };

  const handleAddToCart = (record) => {
    if (record.productId) {
      const existingItemIndex = cartItems.findIndex(cartItem => cartItem.productId === record.productId);
      if (existingItemIndex !== -1) {
        const updatedCartItems = [...cartItems];
        updatedCartItems[existingItemIndex].quantity += 1;
        setCartItems(updatedCartItems);
        toast.info(`${record.name} Added to cart!`);
      } else {
        toast.info(`${record.name} Added to cart!`);
        setCartItems(prevCartItems => [...prevCartItems, {
          productId: record.productId,
          quantity: 1,
          condition: "NM",
          type: "cash",
          product: { ...record },
          price: 0.00,
          skus: record.skus,
          match: record
        }]);
      }
    }
  };

  const handleQuantityChange = (value, record) => {
    const updatedData = csvData.map(item => {
      if (item.productId === record.productId) {
        return { ...item, quantity: value };
      }
      return item;
    });
    setCsvData(updatedData);
  };

  const handlePriceChange = (value, record) => {
    const updatedData = csvData.map(item => {
      if (item.productId === record.productId) {
        return { ...item, price: value };
      }
      return item;
    });
    setCsvData(updatedData);
  };

  const handleAddAllToCart = () => {
    const productsToAdd = csvData.filter(item => item.productId);
    productsToAdd.forEach((product) => {
      toast.info(`${product.name} Added to cart!`);
    })
    setCartItems(prevCartItems => [...prevCartItems, ...productsToAdd.map(item => ({
      productId: item.productId,
      quantity: item.quantity,
      condition: "NM",
      type: "cash",
      selectedType: "Normal",
      selectedCondition: "NM",
      product: { ...item },
      price: 0.00,
      skus: item.skus,
      match: item
    }))]);
  };

  const columns = [
    { title: "Name", dataIndex: "name", key: "name" },
    { title: "Number", dataIndex: "number", key: "number" },
    { title: "Set", dataIndex: "set", key: "set" },
    {
      title: "Match ID",
      key: "match",
      render: (text, record) => (
        <span>
          {record.match ? record.match.productId : "No Match"}
        </span>
      ),
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (text, record) => (
        <InputNumber value={record.price} onChange={(value) => handlePriceChange(value, record)} />
      ),
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      render: (text, record) => (
        <InputNumber value={record.quantity} onChange={(value) => handleQuantityChange(value, record)} />
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Button onClick={() => handleAddToCart(record)} disabled={!record.productId}>Add to Cart</Button>
      ),
    },
  ];
  const filteredData = csvData.filter(item => item.match);

  // Sort the data so that "No Match" items are at the end
  filteredData.sort((a, b) => {
    if (!a.match && b.match) return 1;
    if (a.match && !b.match) return -1;
    return 0;
  });
  return (
    <Modal
      title="Import CSV"
      visible={visible}
      onCancel={onCancel}
      width={1000}
      footer={[
        <Button key="addAllToCart" onClick={handleAddAllToCart} disabled={csvData.filter(item => item.productId).length === 0}>
        Add All to Cart
      </Button>,
       
        <Button key="clear" onClick={clearData}>
          Clear Data
        </Button>,
      <Button key="cancel" onClick={onCancel}>
      Cancel
    </Button>,
      ]}
    >
      <CSVReader
        onUploadAccepted={handleUploadAccepted}
      >
        {({
          getRootProps,
          acceptedFile,
          ProgressBar,
          getRemoveFileProps,
        }) => (
          <>
            <div>
              <button {...getRootProps()}>
                Browse file
              </button>
              <div>
                {acceptedFile && acceptedFile.name}
              </div>
              <button {...getRemoveFileProps()}>
                Remove
              </button>
            </div>
            <ProgressBar />
          </>
        )}
      </CSVReader>
      <Table
        dataSource={filteredData}
        columns={columns}
        pagination={false}
        loading={uploading}
      />
    </Modal>
  );
};

export default CSVModal;
