import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Button, Spin, Alert, notification } from 'antd';
import './MostWantedGrid.css';
import axios from 'axios';

const MostWantedGrid = ({ wanted, config, cartItems, setCartItems, iframe }) => {
  const [mostWanted, setMostWanted] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [exchangeRate, setExchangeRate] = useState(1)
  useEffect(() => {
   
    async function fetchExchangeRate() {
      if (exchangeRate == 1) {
        try {
          const response = await axios.get(
            "https://server1.tcgs.xyz:4000/api/test/buylist_exchange?currencyCode=" +
              config.storeConfig.storeCurrency
          );
          const rate = response.data.data.usdToUserRate;
          console.log(rate);
          const rate2 = response.data.data.eurToUserRate;
          setExchangeRate(rate);
        } catch (error) {
          console.error("Error fetching exchange rate:", error);
        }
      }
    }
    //1.5317 294.98
    fetchExchangeRate();
  }, []);
  useEffect(() => {
    async function fetchMostWantedItems() {
      try {
        await new Promise((resolve) => setTimeout(resolve, 1000)); // Simulated fetch delay
        // Calculate prices for each item and update its details
        const updatedWanted = await Promise.all(wanted.map(async (item) => {
          const { cashPrice, creditPrice } = await calculateDisplayedPrice(item);
          return { ...item, cashPrice, creditPrice };
        }));
        setMostWanted(updatedWanted);
      } catch (error) {
        setError('Failed to fetch items. Please try again.');
      } finally {
        setIsLoading(false);
      }
    }

    fetchMostWantedItems();
  }, [wanted]);

  const calculateDisplayedPrice = async (product) => {
    const startPrice = product.sku.pricingInfo.lowPrice || product.sku.pricingInfo.marketPrice;
    let adjustedPrice = startPrice;
    const conditionAdjustments = {
      'NM': 1,
      'LP': 0.8, // NM - 20%
      'MP': 0.6, // NM - 40%
      'HP': 0.5, // NM - 50%
      'DM': 0.45 // NM - 55%
    };

    if (product.condition in conditionAdjustments) {
      adjustedPrice *= conditionAdjustments[product.condition];
    }

    let cashPrice = adjustedPrice;
    let creditPrice = adjustedPrice;

    // Calculate cash and credit prices based on rules
    if (config) {
      if (config.buylistConfig.enableCash) {
        cashPrice *= Number(config.buylistConfig.cashBuyrate) / 100;
      }
      if (config.buylistConfig.enableCredit) {
        creditPrice *= Number(config.buylistConfig.creditBuyrate) / 100;
      }
    }
    console.log(exchangeRate)
    // Apply exchange rate
    cashPrice *= Number(exchangeRate);
    creditPrice *= Number(exchangeRate);

    return {
      cashPrice,
      creditPrice
    };
  };

  const handleAddToCart = async (product, quantity = 1, priceType = "cash") => {
    const maxQuantity = Number(config.buylistConfig?.maximumBuyQuantity) || 10;

    // Calculating the adjusted price based on the selected price type
    const { cashPrice, creditPrice } = await calculateDisplayedPrice(product);
    const selectedPrice = priceType === "cash" ? cashPrice : creditPrice;

    // Check if the item already exists in the cart
    const existingCartItemIndex = cartItems.findIndex(
      (item) => item.productId === product.productId
    );

    // If item exists, update the quantity (if within limits), else add new item
    if (existingCartItemIndex !== -1) {
      const existingCartItem = cartItems[existingCartItemIndex];
      const updatedQuantity = existingCartItem.quantity + quantity;

      if (updatedQuantity > maxQuantity) {
        notification.error({
          message: `Cannot add more than ${maxQuantity} of the same item.`,
        });
        return;
      }

      existingCartItem.quantity = updatedQuantity;
      cartItems[existingCartItemIndex] = existingCartItem;
    } else {
      if (quantity > maxQuantity) {
        notification.error({
          message: `Cannot add more than ${maxQuantity} of the same item.`,
        });
        return;
      }

      const newCartItem = {
        product,
        productId: product.productId,
        name: product.name,
        quantity,
        price: selectedPrice,
        type: priceType,
        selectedType: product.printing,
        selectedCondition: product.condition,
        image: product.image,
        skus: []
      };

      cartItems.push(newCartItem);
    }

    setCartItems([...cartItems]);
    notification.success({
      message: `${product.name} added to the cart!`,
    });
  };

  if (isLoading) return <Spin size="large" />;
  if (error) return <Alert message={error} type="error" showIcon />;

  return (
    <div>
      <div className="most-wanted-header">
        <h1>Most Wanted Buylist Items</h1>
        <p>Check out our most wanted buylist items below.</p>
      </div>
      <Row gutter={[16, 16]} justify="center">
        {mostWanted.map((item) => (
          <Col key={item.productId} xs={24} sm={12} md={8} lg={6} xl={4}>
            <Card
              hoverable
              className="custom-card"
              cover={<img alt={item.name} src={item.image} loading="lazy" />}
              actions={[
                iframe ? (
                  <Button className="add-to-cart-button">View All</Button>
                ) : (
                  <div className="add-to-cart-buttons">
                    <Button
                      className="add-to-cart-button cash"
                      onClick={() => handleAddToCart(item)}
                      disabled={item.quantity <= 0}
                    >
                      Add to Cart (Cash)
                    </Button>
                    <Button
                      className="add-to-cart-button credit"
                      onClick={() => handleAddToCart(item, 1, "credit")}
                      disabled={item.quantity <= 0}
                    >
                      Add to Cart (Credit)
                    </Button>
                  </div>
                )
              ]}
            >
              <Card.Meta
                title={<p className="item-name">{item.name}</p>}
                description={
                  <>
                    <p className="item-cash-price">Cash: ${Number(item.cashPrice).toFixed(2)}</p>
                    <p className="item-store-credit">Credit: ${Number(item.creditPrice).toFixed(2)}</p>
                  </>
                }
              />
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default MostWantedGrid;
