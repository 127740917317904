import React, { useEffect, useState } from "react";
import { Modal, Button, Checkbox, Select, Table } from "antd";
import axios from "axios";
import { toast } from "react-toastify";

const { Option } = Select;

const CardScanModal = ({
  visible,
  onCancel,
  setSearchQuery,
  cartItems,
  setCartItems,
  rules,
  setSelectedGameProducts,
}) => {
  const [scannedCardResults, setScannedCardResults] = useState([]);
  const [selectedScans, setSelectedScans] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [selectedCashOrCredit, setSelectedCashOrCredit] = useState("cash");
  const [selectedType, setSelectedType] = useState("Normal");
  const [selectedCondition, setSelectedCondition] = useState("NM");
  const [failedScans, setFailedScans] = useState([]);
  const [selectAll, setSelectAll] = useState(false); // State for "Select All" checkbox

  // Function to toggle "Select All" checkbox
  const toggleSelectAll = () => {
    if (selectAll) {
      setSelectedScans([]); // Deselect all scanned cards
    } else {
      // Select all scanned card IDs
      const allProductIds = scannedCardResults.map((card) => card.productId);
      setSelectedScans(allProductIds);
    }
    setSelectAll(!selectAll); // Toggle the "Select All" checkbox state
  };

  const columns = [
    {
      title: (
        <Checkbox
          onChange={toggleSelectAll}
          checked={selectAll}
          indeterminate={
            selectedScans.length > 0 && selectedScans.length < scannedCardResults.length
          }
        >
          Select All
        </Checkbox>
      ),
      dataIndex: "select",
      key: "select",
      render: (text, record) => (
        <Checkbox
          onChange={() => toggleScanSelection(record.productId)}
          checked={selectedScans.includes(record.productId)}
        />
      ),
    },
    {
      title: "Card Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Number",
      dataIndex: "number",
      key: "number",
    },
    {
      title: "Abbreviation",
      dataIndex: "abbreviation",
      key: "abbreviation",
    },
    {
      title: "Quantity in Stock",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
    },
  ];

  const handleScanCard = async (e) => {
    try {
      setUploading(true);

      const fileList = e.target.files;

      const newScannedCardResults = []; // Array to store scanned card results
      const newFailedScans = []; // Array to store failed scans

      // Process each file in the fileList
      for (const file of fileList) {
        const fileIdentifier = file.name;

        // Check if the file is an image
        if (file.type && file.type.startsWith("image/")) {
          // Read the image file as base64
          const reader = new FileReader();

          reader.onload = async (e) => {
            const imageBase64 = e.target.result;

            // Recognize the card using an API endpoint
            const payload = {
              image: imageBase64,
              fileIdentifier,
            };

            try {
              const response = await axios.post(
                "https://server1.tcgs.xyz:4000/api/cardmarket/products/CardVisualSearchNoAuth",
                payload
              );

              if (response.data.success === 1) {
                const result = response.data.data.results[0];
                if (result) {
                  const {
                    productId,
                    name,
                    abbreviation,
                    condition,
                    number,
                    usPrices,
                  } = result;
                  let calculatedPrice = "0.00"; // Default to $0.00

                  if (usPrices && usPrices.length > 0) {
                    // Find the price based on cash or credit
                    const selectedPrice =
                      selectedCashOrCredit === "cash"
                        ? usPrices.find((price) =>
                            price.subTypeName === selectedType
                          )
                        : usPrices.find((price) => price.subTypeName === "Foil");

                    if (selectedPrice) {
                      calculatedPrice = (
                        selectedPrice.marketPrice * 1
                      ).toFixed(2); // Convert to a number and format to 2 decimal places
                    }
                  }

                  // Check if the product with the same productId already exists
                  const existingProductIndex = newScannedCardResults.findIndex(
                    (card) => card.productId === productId
                  );

                  if (existingProductIndex !== -1) {
                    // If it exists, update the quantity
                    newScannedCardResults[existingProductIndex].quantity += 1;
                  } else {
                    // If it doesn't exist, add a new entry
                    newScannedCardResults.push({
                      productId,
                      name,
                      quantity: 1,
                      abbreviation,
                      condition,
                      number,
                      price: calculatedPrice, // Use the calculated price here
                      fileIdentifier,
                    });
                  }

                  setScannedCardResults((prevResults) => [
                    ...prevResults,
                    ...newScannedCardResults,
                  ]);
                } else {
                  console.error(
                    "Card Recognition Failed for",
                    fileIdentifier,
                    ":",
                    response.data.status
                  );
                  newFailedScans.push(fileIdentifier); // Store the failed scan
                }
              } else {
                console.error(
                  "Card Recognition Failed for",
                  fileIdentifier,
                  ":",
                  response.data.status
                );
                newFailedScans.push(fileIdentifier); // Store the failed scan
              }
            } catch (error) {
              console.error("Error processing card:", error);
              newFailedScans.push(fileIdentifier); // Store the failed scan
            }
          };

          reader.readAsDataURL(file);
        }
      }

      // Add the new scanned card results to the existing list
      setUploading(false);
      setFailedScans(newFailedScans); // Update failed scans
    } catch (error) {
      console.error("Error scanning card:", error);
      setUploading(false);
    }
  };

  const handleAddToCart = async (
    productId,
    type,
    selectedType,
    selectedCondition = "NM"
  ) => {
    try {
      let startPrice = 0;
  
      // Find the product with the matching productId
      const product = scannedCardResults.find(
        (card) => card.productId === productId
      );
  
      if (!product) {
        return; // Exit if the product is not found
      }
  
      startPrice = product.price;
  
      if (type === "cash") {
        const cashBuyrate = Number(rules.buylistConfig.cashBuyrate);
        startPrice = ((startPrice * cashBuyrate) / 100).toFixed(2);
      } else {
        const creditBuyrate = Number(rules.buylistConfig.creditBuyrate);
        startPrice = ((startPrice * creditBuyrate) / 100).toFixed(2);
      }
  
      const existingCartItemIndex = cartItems.findIndex(
        (item) =>
          item.product.productId === productId &&
          item.type === type &&
          item.selectedType === selectedType &&
          item.selectedCondition === selectedCondition
      );
  
      if (existingCartItemIndex !== -1) {
        // The card is already in the cart, do nothing or you can update the quantity as needed
        toast.info(`${product.name} is already in the cart!`);
      } else {
        // Create a new cart item with quantity 1
        const cartItem = {
          product,
          type,
          quantity: 1,
          selectedType,
          selectedCondition,
          price: type === "cash" ? startPrice : startPrice,
        };
  
        // Check if the total quantity exceeds the maximum allowed
        const maxQuantity = Number(rules.buylistConfig?.maximumBuyQuantity) || 10;
        if (cartItem.quantity > maxQuantity) {
          toast.error(
            `Cannot add more than ${maxQuantity} cards of the same type and condition.`
          );
          return;
        }
  
        // Update the cart state using the functional form
        setCartItems((prevCart) => [...prevCart, cartItem]);
        toast.info(`${product.name} Added to cart!`);
      }
    } catch (error) {
      console.error("Error adding to cart:", error);
    }
  };
  

  const handleAddSelectedToCart = () => {
    const selectedCards = selectedScans.map((productId) =>
      scannedCardResults.find((card) => card.productId === productId)
    );

    // Create a map to store the selected cards based on productId as the key
    const selectedCardsMap = new Map();

    for (const selectedCard of selectedCards) {
      // Check if the productId already exists in the map
      if (selectedCardsMap.has(selectedCard.productId)) {
        // If it exists, increment the quantity
        const existingCard = selectedCardsMap.get(selectedCard.productId);
        existingCard.quantity += 1;
      } else {
        // If it doesn't exist, add it to the map
        selectedCardsMap.set(selectedCard.productId, {
          ...selectedCard,
          quantity: 1,
        });
      }
    }

    // Convert the map values back to an array of selected cards
    const mergedSelectedCards = [...selectedCardsMap.values()];

    // Loop through mergedSelectedCards and add them to the cart using your existing logic
    for (const mergedCard of mergedSelectedCards) {
      // You can call your existing `handleAddToCart` function here
      handleAddToCart(
        mergedCard.productId, // Use productId as the unique identifier
        selectedCashOrCredit, // Modify this based on your requirements
        mergedCard.quantity, // Use the existing quantity
        selectedType, // Modify this based on your requirements
        mergedCard.condition // Modify this based on your requirements
      );
    }

    // Clear the selected scans after adding them to the cart
    setSelectedScans([]);
  };

  const toggleScanSelection = (productId) => {
    const updatedSelection = [...selectedScans];
    if (updatedSelection.includes(productId)) {
      updatedSelection.splice(updatedSelection.indexOf(productId), 1);
    } else {
      updatedSelection.push(productId);
    }
    setSelectedScans(updatedSelection);
  };

  // Filter out duplicate cards based on card name
  const uniqueScannedCardResults = scannedCardResults.filter(
    (card, index, self) =>
      index === self.findIndex((c) => c.name === card.name)
  );
  const clearScans = () => {
    setScannedCardResults([]); // Clear the scanned results
    setFailedScans([]); // Clear any failed scans
    setSelectedScans([]); // Deselect all selections
    setSelectAll(false); // Uncheck the 'Select All' checkbox
  };

  return (
    <Modal
      title="Scan Cards"
      visible={visible}
      onCancel={onCancel}
      width={"800px"}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          Cancel
        </Button>,
        <Button
          key="addToCart"
          type="primary"
          onClick={handleAddSelectedToCart}
        >
          Add Selected to Cart
        </Button>,
        <Button key="clearScans" onClick={clearScans}>Clear Scans</Button>, // Add the clear scans button
      ]}
    >
      <input
        type="file"
        accept="image/*"
        multiple
        onChange={handleScanCard}
      />

      {/* Display failed scans, if any */}
      {failedScans.length > 0 && (
        <div style={{ color: "red", marginBottom: "16px" }}>
          Failed Scans: {failedScans.join(", ")}
        </div>
      )}

      <div style={{ marginBottom: "16px" }}>
        Select Cash or Credit:
        <Select
          value={selectedCashOrCredit}
          onChange={(value) => setSelectedCashOrCredit(value)}
        >
          <Option value="cash">Cash</Option>
          <Option value="credit">Credit</Option>
        </Select>
      </div>

      <div style={{ marginBottom: "16px" }}>
        Select Type:
        <Select
          value={selectedType}
          onChange={(value) => setSelectedType(value)}
        >
          <Option value="Normal">Normal</Option>
          <Option value="Foil">Foil</Option>
          {/* Add other type options as needed */}
        </Select>
      </div>

      <div style={{ marginBottom: "16px" }}>
        Select Condition:
        <Select
          value={selectedCondition}
          onChange={(value) => setSelectedCondition(value)}
        >
          <Option value="NM">NM (Near Mint)</Option>
          <Option value="LP">LP (Lightly Played)</Option>
          {/* Add other condition options as needed */}
        </Select>
      </div>

      {/* Display scanned card information in an Ant Design table */}
      <Table
        dataSource={uniqueScannedCardResults}
        columns={columns}
        pagination={false}
        rowKey={(record) => record.productId.toString()}
      />
    </Modal>
  );
};

export default CardScanModal;
