import React, { useState } from 'react';
import { Modal, Button, Input, Form, Select, Steps, message, Radio, Spin, Checkbox } from 'antd';
import axios from 'axios';

const { Step } = Steps;
const { Option } = Select;

const OnboardingForm = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({}); // New state to accumulate form data

  const nextStep = () => {
    form.validateFields().then((values) => {
      // Merge current step's form data with the accumulated data
      setFormData({ ...formData, ...values });
      setCurrentStep(currentStep + 1);
    }).catch((info) => {
      console.log('Validate Failed:', info);
    });
  };

  const prevStep = () => {
    form.validateFields().then((values) => {
      // Also merge when going back in case changes were made
      setFormData({ ...formData, ...values });
      setCurrentStep(currentStep - 1);
    }).catch((info) => {
      console.log('Validate Failed:', info);
    });
  };

  const onFinish = async () => {
    setLoading(true);

    const finalData = { ...formData, ...form.getFieldsValue() }; // Ensure last step's data is included
    console.log('Received values of form: ', finalData);

    try {
      const apiResponse = await axios.post('https://api.tcgsync.com:4000/api/buylist/logonboarding', finalData);
      console.log('Onboarding data saved:', apiResponse.data);
     
      message.success('Onboarding Complete!');
    } catch (error) {
      console.error('Error during onboarding:', error);
      message.error('Failed to complete onboarding. Please try again.');
    }

    setLoading(false);
  };

  const steps = [
    {
      title: 'Account Info',
      content: (
        <>
          <Form.Item
            label="Email"
            name="email"
            rules={[{ required: true, message: 'Please input your email!' }]}
          >
            <Input placeholder="example@example.com" />
          </Form.Item>
          <Form.Item
            label="First and Last Name for Main Point of Contact"
            name="contactName"
            rules={[{ required: false }]}
          >
            <Input.TextArea autoSize={{ minRows: 2 }} placeholder="John Smith - test@email.com - Manager" />
          </Form.Item>
          <Form.Item
            label="Business Name"
            name="businessName"
            rules={[{ required: false }]}
          >
            <Input placeholder="Your business name" />
          </Form.Item>
          <Form.Item
            label="Business Industry"
            name="businessIndustry"
            rules={[{ required: false }]}
          >
            <Input placeholder="e.g., Retail, eCommerce" />
          </Form.Item>
          <Form.Item
            label="Region"
            name="region"
            rules={[{ required: false }]}
          >
            <Input placeholder="Your region" />
          </Form.Item>
          <Form.Item
            label="Timezone"
            name="timezone"
            rules={[{ required: false }]}
          >
            <Input placeholder="e.g., GMT, EST" />
          </Form.Item>
          <Form.Item
            label="Platform you sell on"
            name="sellingPlatform"
            rules={[{ required: false }]}
          >
            <Input placeholder="e.g., Shopify, WooCommerce" />
          </Form.Item>
          <Form.Item
            label="Shopify web url"
            name="shopifyUrl"
            rules={[{ required: false }]}
          >
            <Input placeholder="Your Shopify web URL" />
          </Form.Item>
          <Form.Item
            label="Official web URL"
            name="officialUrl"
            rules={[{ required: false }]}
          >
            <Input placeholder="Your official web URL" />
          </Form.Item>
        </>
      ),
    },
    {
      title: 'TCGSync Questions',
      content: (
        <>
          <Form.Item
            name="withProvider"
            label="Are you currently with another provider?"
            rules={[{ required: false }]}
          >
            <Radio.Group>
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) => prevValues.withProvider !== currentValues.withProvider}
          >
            {({ getFieldValue }) =>
              getFieldValue('withProvider') ? (
                <>
                  <Form.Item
                    name="otherProviderName"
                    label="If Yes, what other provider are you with?"
                    rules={[{ required: true, message: 'Please input the provider name!' }]}
                  >
                    <Input placeholder="Provider name" />
                  </Form.Item>
                  <Form.Item
                    name="tcgSyncUsername"
                    label="What is your TCGSync Username?"
                    rules={[{ required: true, message: 'Please input your TCGSync username!' }]}
                  >
                    <Input placeholder="Your TCGSync username" />
                  </Form.Item>
                  <Form.Item
                    name="tcgSyncPlan"
                    label="What Plan are you on with TCGSync?"
                    rules={[{ required: true, message: 'Please select your TCGSync plan!' }]}
                  >
                    <Select placeholder="Select TCGSync plan">
                      <Option value="Free Theme Design on Fixed Monthly or Annual, Free Card Scanner with Fixed Annual">Free Theme Design on Fixed Monthly or Annual, Free Card Scanner with Fixed Annual</Option>
                      <Option value="$75 + 2% Fees capped at $500">$75 + 2% Fees capped at $500</Option>
                      <Option value="$399 Fixed Monthly">$399 Fixed Monthly</Option>
                      <Option value="$3999 Fixed Yearly">$3999 Fixed Yearly</Option>
                      <Option value="A La Carte">A La Carte</Option>
                      <Option value="Lifetime Grandfathered Customer">Lifetime Grandfathered Customer</Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name="receivedCardScanner"
                    label="If you are on Fixed Annual, have you received your free Card Scanner?"
                    rules={[{ required: true, message: 'Please select an option!' }]}
                  >
                    <Radio.Group>
                      <Radio value={true}>Yes</Radio>
                      <Radio value={false}>No</Radio>
                    </Radio.Group>
                  </Form.Item>
                </>
              ) : null
            }
          </Form.Item>
          {/* Additional TCGSync questions can be added here */}
        </>
      ),
    },
    {
      title: 'Shopify Questions',
      content: (
        <>
          <Form.Item
            name="collabCode"
            label="What is your Collab Code?"
            extra={<a href="https://publish.obsidian.md/tcgsync/Shopify+Setup/Providing+your+Shopify+Collab+Code+and+Store+URL" target="_blank" rel="noopener noreferrer">Tutorial</a>}
            rules={[{ required: false }]}
          >
            <Input placeholder="Collab Code" />
          </Form.Item>
          <Form.Item
            name="hasCardSingleCatalogs"
            label="Do you have card single catalogs on your Shopify from BinderPOS?"
            rules={[{ required: false }]}
          >
            <Radio.Group>
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            name="autopricingRules"
            label="What autopricing rules do you use?"
            rules={[{ required: false }]}
          >
            <Input placeholder="Your autopricing rules" />
          </Form.Item>
          <Form.Item
            name="hasTCGSyncBuylist"
            label="Do you have the TCGSync Buylist setup?"
            rules={[{ required: false }]}
          >
            <Radio.Group>
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            name="needTCGSyncBuylist"
            label="Do you need the TCGSync Buylist?"
            rules={[{ required: false }]}
          >
            <Radio.Group>
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            name="hasTCGSyncApp"
            label="Do you have the TCGSync personalized app?"
            rules={[{ required: false }]}
          >
            <Radio.Group>
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            name="hasTCGSyncEventModule"
            label="Do you have the TCGSync Event Module setup?"
            rules={[{ required: false }]}
          >
            <Radio.Group>
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            name="needTCGSyncEventModule"
            label="Do you need the TCGSync Event Module?"
            rules={[{ required: false }]}
          >
            <Radio.Group>
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            name="needNewTheme"
            label="Do you need a new theme?"
            rules={[{ required: false }]}
          >
            <Radio.Group>
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            name="needPOS"
            label="Do you need a POS? (Point of Sale)"
            rules={[{ required: false }]}
          >
            <Radio.Group>
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            name="POSAssistance"
            label="If you need a POS, what assistance do you need?"
            rules={[{ required: false }]}
          >
            <Input.TextArea autoSize={{ minRows: 2 }} placeholder="Your POS assistance needs" />
          </Form.Item>
          <Form.Item
            name="numberOfRegisters"
            label="If you need a POS, how many registers would you need?"
            rules={[{ required: false }]}
          >
            <Input placeholder="Number of registers needed" />
          </Form.Item>
        </>
      ),
    },
    {
      title: 'Additional Information',
      content: (
        <>
          <Form.Item
            name="additionalInfo"
            label="Anything else we need to know?"
            rules={[{ required: false }]}
          >
            <Input.TextArea autoSize={{ minRows: 2 }} placeholder="Additional information" />
          </Form.Item>
          <Form.Item
            name="onboardingTimeline"
            label="Onboarding Timeline to use the POS"
            rules={[{ required: false }]}
          >
            <Input placeholder="e.g., Within 2 weeks" />
          </Form.Item>
          <Form.Item
            name="merchantMatchOffer"
            valuePropName="checked"
          >
            <Checkbox>
              I'm interested in learning more about lower card rates and receiving a complimentary card machine through Merchant Match (available in the USA only).
            </Checkbox>
          </Form.Item>
        </>
      ),
    }
    
  ];
  

  return (
    <Modal
      title="Onboarding"
      visible={true}
      footer={null}
      width={1000}
      maskClosable={false}
      destroyOnClose={true}
    >
      <Spin spinning={loading}>
        <Steps current={currentStep} style={{ marginBottom: '20px' }}>
          {steps.map(item => (
            <Step key={item.title} title={item.title} />
          ))}
        </Steps>
        <div className="steps-content">
          <Form form={form} onFinish={onFinish}>
            {steps[currentStep].content}
          </Form>
        </div>
        <div className="steps-action" style={{ textAlign: 'right' }}>
          {currentStep > 0 && (
            <Button style={{ margin: '0 8px' }} onClick={prevStep}>
              Previous
            </Button>
          )}
          {currentStep < steps.length - 1 && (
            <Button type="primary" onClick={nextStep}>
              Next
            </Button>
          )}
          {currentStep === steps.length - 1 && (
            <Button type="primary" htmlType="submit" onClick={() => form.submit()}>
              Submit
            </Button>
          )}
        </div>
      </Spin>
    </Modal>
  );
};


export default OnboardingForm;
