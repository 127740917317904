const BASE_URL = 'https://server1.tcgs.xyz:4000/api/buylist';
const BASE_URL_TMT = 'https://api.trackmytcg.com:4000/api';

// Helper function for building URLs
const buildUrl = (path, params = {}) => {
  const queryParams = new URLSearchParams(params);
  return `${BASE_URL}${path}?${queryParams.toString()}`;
};
// Helper function for building URLs
const buildTmtUrl = (path, params = {}) => {
  const queryParams = new URLSearchParams(params);
  return `${BASE_URL_TMT}${path}?${queryParams.toString()}`;
};

const handleFetch = async (url, flag  = false) => {
  try {
    const response = await fetch(url);
    
    if (!response.ok) {
      throw new Error(`Request failed with status ${response.status}`);
    }
    
    if(flag == true){
      const data = await response.json()
      console.log(data.data.results[0])
      return data.data.results[0]
    }else{
      return await response.json();
    }
   
  } catch (error) {
    console.error('Error:', error.message);
    throw error;
  }
};

export const getStoreData = async (storeId) => {
  const url = buildUrl(`/store`, { store_id: storeId });
  return handleFetch(url);
};

export const getTMT = async (tmtID, tmtCatchPhrase) => {
  // Construct the URL with appropriate query parameters
  const url = buildTmtUrl('/inventory/tmt', { uuid: tmtID, phrase: tmtCatchPhrase });

  // Call handleFetch to perform the GET request
  return handleFetch(url);
};


export const getStoreEvents = async (storeId) => {
  const url = buildUrl(`/store/events`, { store_id: storeId });
  return handleFetch(url);
};

export const subscriptToEvent = async (storeId, email) => {
  const url = buildUrl(`/store/events/subscribe`, { store_id: storeId, email: email });
  return handleFetch(url);
};

export const registerInterest = async (storeId, email, firstName, lastName, eventId) => {
  const url = buildUrl(`/store/events/interest`, { store_id: storeId, email: email, firstName: firstName, lastName: lastName, eventId: eventId });
  return handleFetch(url);
};

export const getStoreSets = async (gameName) => {
  const url = buildUrl(`/store/sets`, { game: gameName });
  return handleFetch(url);
};

export const nameNumberSearch = async (name,number,set) => {
  const url = buildUrl(`/namenumber`, { itemName: name, itemNumber: number, itemSet:set });
  return handleFetch(url, true);
};

export const getProductsForGame = async (storeOwner, gameName, sets, page, pageSize, searchQuery, setCode, sortCriteria, rarityFilter) => {
  const params = {
    store_owner: storeOwner,
    game: gameName,
    page,
    pageSize,
    sortCriteria,
    rarityFilter
  };

  if (sets && sets.length > 0) {
    params.sets = sets.join(',');
  }

  if (searchQuery && searchQuery.trim() !== '') {
    params.search = searchQuery;
  }

  if (setCode && setCode.trim() !== '') {
    params.setCode = setCode;
  }

  const url = buildUrl(`/products`, params);
  return handleFetch(url);
};

export const getTCGPlayerPrice = async (product) => {
  try {
    if (product.productId) {
      const tcgPlayerId = product.productId;
      const url = buildUrl(`/inventory/price`, { tcg_player_id: tcgPlayerId, all: false });
      return handleFetch(url);
    }
  } catch (error) {
    console.error('Error fetching TCGPlayer pricing data:', error);
    throw error;
  }
};

export const checkStoreStock = async (item, storeOwner) => {
  try {
    const url = buildUrl(`/store/stock`, { store_owner: storeOwner });
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ item }),
    });
    const data = await response.json();
    console.log('RESPONSE', data);
    return data;
  } catch (error) {
    console.error(`Error fetching products for ${storeOwner}:`, error);
    throw error;
  }
};



export const placeOrder = async (order, staffMember) => {
  try {
    const url = buildUrl(`/store/order`);
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ order, staffMember }),
    });
    const data = await response.json();
    return data.savedOrder;
  } catch (error) {
    console.error('Error placing order:', error);
    throw error;
  }
};
