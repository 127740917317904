import React, { useState, useEffect } from "react";
import moment from "moment";
import {
  LeftOutlined,
  MoreOutlined,
  RightOutlined,
  ShoppingCartOutlined,
  CalendarOutlined,
  TableOutlined,
  AppstoreOutlined,
} from "@ant-design/icons";
import { Tooltip, Modal, Button, List, notification, Input, Form, Checkbox, Table } from "antd";
import {
  getStoreEvents,
  registerInterest,
  subscriptToEvent,
} from "../handler/APIService";
import "./EventCalendar.css";

const EventCalendar = () => {
  const [storeId, setStoreId] = useState(null);
  const [currentMoment, setCurrentMoment] = useState(moment());
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [eventsModalVisible, setEventsModalVisible] = useState(false);
  const [dayEvents, setDayEvents] = useState([]);
  const [storeEvents, setStoreEvents] = useState([]);
  const [storeData, setStoreData] = useState([]);
  const [monthEventsModalVisible, setMonthEventsModalVisible] = useState(false);
  const [monthEvents, setMonthEvents] = useState([]);
  const [subscriptionVisible, setSubscriptionVisible] = useState(false);
  const [email, setEmail] = useState("");
  const [registerInterestVisible, setRegisterInterestVisible] = useState(false);
  const [shouldSubscribe, setShouldSubscribe] = useState(true);
  const [registrationDetails, setRegistrationDetails] = useState({
    firstName: "",
    lastName: "",
    email: "",
    eventId: 0,
  });
  const [isTableView, setIsTableView] = useState(false);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        if (storeEvents.length === 0) {
          await new Promise((resolve) => setTimeout(resolve, 1000));
          const urlParams = new URLSearchParams(window.location.search);
          const storeId = urlParams.get("store_id");
          const data = await getStoreEvents(storeId);
          setStoreId(storeId);
          setStoreEvents(data.events);
          setStoreData(data);
        }
      } catch (error) {
        console.error("Error fetching events:", error);
      }
    };

    fetchEvents();
  }, [storeEvents]);

  useEffect(() => {
    console.log("Registered Interested:", registrationDetails);
  }, [registrationDetails]);

  const navigateMonths = (direction) => {
    setCurrentMoment((prevMoment) =>
      prevMoment.clone().add(direction === "prev" ? -1 : 1, "month")
    );
  };

  const isEventOnDay = (event, day) => {
    const eventStartDate = moment(`${event.start}T${event.startTime}`);
    if (event.recurring === "weekly") {
      return (
        day.isoWeekday() === eventStartDate.isoWeekday() &&
        day.diff(eventStartDate, "weeks") % 1 === 0
      );
    } else if (event.recurring === "monthly") {
      return (
        day.date() === eventStartDate.date() &&
        day.diff(eventStartDate, "months") % 1 === 0
      );
    }
    return eventStartDate.isSame(day, "day");
  };

  const handleEventClick = (event) => {
    setSelectedEvent(event);
    setEventsModalVisible(true);
  };

  const handleDayClick = (day) => {
    const eventsForDay = storeEvents.filter((event) =>
      isEventOnDay(event, day)
    );
    setDayEvents(eventsForDay);
    setEventsModalVisible(true);
  };

  const handleMonthDisplayClick = () => {
    const eventsForMonth = [];
    const daysInMonth = currentMoment.daysInMonth();

    for (let i = 1; i <= daysInMonth; i++) {
      const dayOfMonth = currentMoment.clone().date(i);
      const eventsForDay = storeEvents.filter((event) =>
        isEventOnDay(event, dayOfMonth)
      );
      eventsForMonth.push(...eventsForDay);
    }

    setMonthEvents(eventsForMonth);
    setMonthEventsModalVisible(true);
  };

  const closeModal = () => {
    setSelectedEvent(null);
    setEventsModalVisible(false);
  };

  const generateDaysMatrix = () => {
    const startDay = currentMoment.clone().startOf("month").startOf("week");
    const daysMatrix = Array.from({ length: 6 }, (_, i) =>
      Array.from({ length: 7 }, (_, j) =>
        startDay.clone().add(i * 7 + j, "day")
      )
    );
    return daysMatrix;
  };

  const isToday = (day) => day.isSame(moment(), "day");

  const handleSubscribe = async (storeId, email) => {
    await subscriptToEvent(storeId, email);
    notification.success({
      message: "Subscribed!",
      description: "You will receive alerts for upcoming events.",
    });
    setSubscriptionVisible(false);
  };

  const handleAddToCalendar = (event) => {
    const eventStartDate = moment(`${event.start}T${event.startTime}`);
    const eventEndDate = eventStartDate.clone().add(event.duration, "hours");

    const formattedStartDate = eventStartDate.format("YYYYMMDD[T]HHmmss[Z]");
    const formattedEndDate = eventEndDate.format("YYYYMMDD[T]HHmmss[Z]");

    const calendarEvent = [
      "BEGIN:VCALENDAR",
      "VERSION:2.0",
      "BEGIN:VEVENT",
      `DTSTART:${formattedStartDate}`,
      `DTEND:${formattedEndDate}`,
      `SUMMARY:${event.title}`,
      `DESCRIPTION:${event.description}`,
      `LOCATION:${event.location}`,
      "END:VEVENT",
      "END:VCALENDAR",
    ].join("\n");

    const blob = new Blob([calendarEvent], {
      type: "text/calendar;charset=utf-8",
    });
    const url = window.URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "event.ics");

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleSubscriptionToggle = () => {
    setSubscriptionVisible(!subscriptionVisible);
  };

  const uniqueGames = [...new Set(storeEvents.map((event) => event.game))];
  
  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      render: (text, record) => (
        <a href={record.ticketBuyUrl} target="_blank" rel="noopener noreferrer">
          {text}
        </a>
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Start",
      dataIndex: "start",
      key: "start",
      render: (text, record) => moment(`${record.start}T${record.startTime}`).format("LLLL"),
    },
    {
      title: "Duration (hours)",
      dataIndex: "duration",
      key: "duration",
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
    },
    {
      title: "Game",
      dataIndex: "game",
      key: "game",
      filters: uniqueGames.map((game) => ({ text: game, value: game })),
      onFilter: (value, record) => record.game.includes(value),
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <div>
          <Button
            href={record.ticketBuyUrl}
            target="_blank"
            icon={<ShoppingCartOutlined />}
            type="primary"
          >
            Buy Tickets
          </Button>
          <Button
            onClick={() => handleAddToCalendar(record)}
            icon={<CalendarOutlined />}
          >
            Add to Calendar
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div className="event-calendar">
      <div className="calendar-header">
        <Button
          type="text"
          icon={<LeftOutlined />}
          onClick={() => navigateMonths("prev")}
          className="nav-arrow"
        />
        <span className="month-display" onClick={handleMonthDisplayClick}>
          {currentMoment.format("MMMM YYYY")}
        </span>
        <Button
          type="text"
          icon={<RightOutlined />}
          onClick={() => navigateMonths("next")}
          className="nav-arrow"
        />
        <Button
          type="text"
          icon={isTableView ? <AppstoreOutlined /> : <TableOutlined />}
          onClick={() => setIsTableView(!isTableView)}
          className="view-toggle"
        >
          {isTableView ? "Calendar View" : "Table View"}
        </Button>
      </div>

      {isTableView ? (
        <Table dataSource={storeEvents} columns={columns} rowKey="id" />
      ) : (
        <div className="month-grid">
          {moment.weekdaysShort().map((dayName, index) => (
            <div key={index} className="day-header">
              {dayName}
            </div>
          ))}
          {generateDaysMatrix().map((week, weekIndex) => (
            <React.Fragment key={weekIndex}>
              {week.map((day, dayIndex) => (
                <div
                  key={dayIndex}
                  className={`day-cell ${
                    !day.isSame(currentMoment, "month") ? "not-current-month" : ""
                  } ${isToday(day) ? "today" : ""} ${
                    storeEvents.some((event) => isEventOnDay(event, day))
                      ? "has-events"
                      : ""
                  }`}
                  onClick={() => handleDayClick(day)}
                >
                  <div className="date-number">{day.format("D")}</div>
                  <div className="event-icons">
                    {storeEvents.filter((event) => isEventOnDay(event, day))
                      .length > 3 && (
                      <Tooltip title="More events">
                        <MoreOutlined className="event-image-icon" />
                      </Tooltip>
                    )}
                    {storeEvents
                      .filter((event) => isEventOnDay(event, day))
                      .slice(0, 3)
                      .map((event, index) => (
                        <Tooltip key={index} title={event.title}>
                          <img
                            src={event.imageUrl}
                            alt={event.title}
                            className="event-image-icon"
                            onClick={() => handleEventClick(event)}
                          />
                        </Tooltip>
                      ))}
                  </div>
                </div>
              ))}
            </React.Fragment>
          ))}
        </div>
      )}

      {selectedEvent && (
        <Modal
          title={selectedEvent.title}
          visible={eventsModalVisible}
          onCancel={closeModal}
          footer={null}
        >
          <img
            src={selectedEvent.imageUrl}
            alt={selectedEvent.title}
            className="modal-image"
          />
          <div className="event-details-modal">
            <p>
              <strong>Description:</strong> {selectedEvent.description}
            </p>
            <div>
              <strong>Start:</strong>{" "}
              {moment(
                selectedEvent.start + "T" + selectedEvent.startTime
              ).format("LLLL")}
            </div>
            <div>
              <strong>Finish:</strong>{" "}
              {moment(selectedEvent.start + "T" + selectedEvent.startTime)
                .add(selectedEvent.duration, "hours")
                .format("LLLL")}
            </div>

            <p>
              <strong>Duration:</strong> {selectedEvent.duration} hours
            </p>
            <p>
              <strong>Location:</strong> {selectedEvent.location}
            </p>
            <p>
              <strong>Game:</strong> {selectedEvent.game}
            </p>
          </div>
          <List.Item>
            <div>
              {registrationDetails.email ? (
                <Button
                  href={selectedEvent.ticketBuyUrl}
                  target="_blank"
                  icon={<ShoppingCartOutlined />}
                >
                  Buy Tickets (${selectedEvent.ticketPrice})
                </Button>
              ) : (
                <Button
                  type="primary"
                  onClick={() => setRegisterInterestVisible(true)}
                >
                  Register for Event
                </Button>
              )}
              <Button
                onClick={() => handleAddToCalendar(selectedEvent)}
                icon={<CalendarOutlined />}
              >
                Add to Calendar
              </Button>
            </div>
          </List.Item>
        </Modal>
      )}

      <Modal
        title="Events"
        visible={eventsModalVisible && !selectedEvent}
        onCancel={closeModal}
        footer={null}
      >
        <List
          itemLayout="horizontal"
          dataSource={dayEvents}
          renderItem={(item) => (
            <>
              <List.Item>
                <List.Item.Meta
                  avatar={
                    <img
                      src={item.imageUrl}
                      alt={item.title}
                      style={{ width: 50, height: 50 }}
                    />
                  }
                  title={
                    <a
                      href={item.ticketBuyUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {item.title}
                    </a>
                  }
                  description={
                    <>
                      {item.description}
                      <div>
                        <strong>Start:</strong>{" "}
                        {moment(item.start).format("LT")}
                      </div>
                      <div>
                        <strong>Finish:</strong>{" "}
                        {moment(item.start)
                          .add(item.duration, "hours")
                          .format("LT")}
                      </div>
                    </>
                  }
                />
              </List.Item>
            </>
          )}
        />
      </Modal>
      <Modal
        title={`Events for ${currentMoment.format("MMMM YYYY")}`}
        visible={monthEventsModalVisible}
        onCancel={() => setMonthEventsModalVisible(false)}
        footer={null}
      >
        <List
          itemLayout="horizontal"
          dataSource={monthEvents}
          renderItem={(item) => (
            <List.Item>
              <List.Item.Meta
                avatar={
                  <img
                    src={item.imageUrl}
                    alt={item.title}
                    style={{ width: 50, height: 50 }}
                  />
                }
                title={
                  <a
                    href={item.ticketBuyUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {item.title}
                  </a>
                }
                description={item.description}
              />
              <div>
                <Button
                  href={item.ticketBuyUrl}
                  target="_blank"
                  icon={<ShoppingCartOutlined />}
                  type="primary"
                >
                  Buy Tickets
                </Button>
                <Button
                  onClick={() => handleAddToCalendar(item)}
                  icon={<CalendarOutlined />}
                >
                  Add to Calendar
                </Button>
              </div>
            </List.Item>
          )}
        />
      </Modal>
      <Modal
        title="Subscribe for Alerts"
        visible={subscriptionVisible}
        onCancel={() => setSubscriptionVisible(false)}
        footer={null}
      >
        <Form onFinish={handleSubscribe}>
          <Form.Item
            label="Email"
            name="email"
            rules={[{ required: true, message: "Please input your email!" }]}
          >
            <Input value={email} onChange={(e) => setEmail(e.target.value)} />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Subscribe
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="Register Interest"
        visible={registerInterestVisible}
        onCancel={() => setRegisterInterestVisible(false)}
        footer={null}
      >
        <Form
          onFinish={(values) => {
            registerInterest(
              storeId,
              values.email,
              values.firstName,
              values.lastName,
              selectedEvent.id
            )
              .then((response) => {
                if (response.success) {
                  if (shouldSubscribe) {
                    handleSubscribe(storeId, values.email);
                  }
                  notification.success({
                    message: "Interest Registered!",
                    description:
                      "You have successfully registered your interest for this event.",
                  });
                  window.location.href = selectedEvent.ticketBuyUrl;
                  setRegisterInterestVisible(false); // Close the registration modal
                } else {
                  notification.error({
                    message: "Error",
                    description:
                      response.message ||
                      "An error occurred while registering your interest.",
                  });
                }
              })
              .catch((error) => {
                console.error("Error registering interest:", error);
                notification.error({
                  message: "Error",
                  description:
                    "An error occurred while registering your interest. Please try again later.",
                });
              });
          }}
        >
          <Form.Item
            label="First Name"
            name="firstName"
            rules={[
              { required: true, message: "Please input your first name!" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Last Name"
            name="lastName"
            rules={[
              { required: true, message: "Please input your last name!" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Email"
            name="email"
            rules={[{ required: true, message: "Please input your email!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item>
            <Checkbox checked={shouldSubscribe} onChange={(e) => setShouldSubscribe(!shouldSubscribe)}>
              Subscribe to be notified of future events.
            </Checkbox>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default EventCalendar;
