import React, { useState, useEffect } from "react";
import { Button, Image, InputNumber, Select, Tooltip, Slider } from "antd";
import { ShoppingCartOutlined } from "@ant-design/icons";
import "./ProductItem.css";
import { toast } from "react-toastify";
import getSymbolFromCurrency from "currency-symbol-map";

const { Option } = Select;

const ProductItem = ({
    product,
    rules,
    exchangeRate,
    cartItems,
    setCartItems,
    handleCheckout,
}) => {
    const [quantity, setQuantity] = useState(1);
    const [selectedType, setSelectedType] = useState("Normal");
    const [selectedCondition, setSelectedCondition] = useState("NM");
    const [displayedCashPrice, setDisplayedCashPrice] = useState(0);
    const [originalCashPrice, setOriginalCashPrice] = useState(0);
    const [displayedCreditPrice, setDisplayedCreditPrice] = useState(0);
    const [splitPercentage, setSplitPercentage] = useState(100);
    const [isAddedToCart, setIsAddedToCart] = useState(false);

    useEffect(() => {
        calculateDisplayedPrice();
    }, [selectedType, selectedCondition, splitPercentage]);

    const calculateDisplayedPrice = () => {
        let startPrice = 0;

        const matchingSku = product.skus.find(
            (sku) =>
                sku.condAbbr === selectedCondition &&
                    sku.printingName === selectedType !== "" ? selectedType : printingTypes[0] &&
                sku.pricingInfo
        );

        if (matchingSku) {
            startPrice = parseFloat(
                matchingSku.pricingInfo.lowPrice || matchingSku.pricingInfo.marketPrice || 0
            );
        }

        let adjustedPrice = startPrice;

        const conditionAdjustments = {
            'NM': 1,
            'LP': 0.8,
            'MP': 0.6,
            'HP': 0.5,
            'DM': 0.45
        };

        if (selectedCondition in conditionAdjustments) {
            adjustedPrice *= conditionAdjustments[selectedCondition];
        }

        let cashPrice = adjustedPrice;
        let creditPrice = adjustedPrice;

        if (rules.buylistConfig.enableCash) {
            cashPrice *= Number(rules.buylistConfig.cashBuyrate) / 100;
            setOriginalCashPrice(cashPrice)
        }
        if (rules.buylistConfig.enableCredit) {
            creditPrice *= Number(rules.buylistConfig.creditBuyrate) / 100;
        }


        cashPrice *= (splitPercentage / 100);
        creditPrice *= ((100 - splitPercentage) / 100);

      
        if (rules.storeConfig.storeCurrency !== "USD") {
            cashPrice *= exchangeRate;
            creditPrice *= exchangeRate;
        }

        setDisplayedCashPrice(parseFloat(cashPrice.toFixed(2)));
        setDisplayedCreditPrice(parseFloat(creditPrice.toFixed(2)));
    };

    const handleTypeChange = (value) => {
        setSelectedType(value);
    };

    const handleSliderChange = (value) => {
        setSplitPercentage(value);
    };

    const handleAddToCart = (
        product,
        type,
        newQuantity,
        selectedType,
        selectedCondition
    ) => {
        const maxQuantity =
            Number(rules.buylistConfig?.maximumBuyQuantity) || 10;

        if (product.invQuantity > maxQuantity) {
            toast.error("This item cannot be added to the cart.");
            return;
        }

        const existingCartItemIndex = cartItems.findIndex(
            (item) =>
                item.product.productId === product.productId &&
                item.type === type &&
                item.selectedType === selectedType &&
                item.selectedCondition === selectedCondition
        );

        let startPrice = null;

        if (product.skus && selectedCondition) {
            const matchingSku = product.skus.find(
                (sku) =>
                    sku.condAbbr === selectedCondition &&
                    sku.printingName === selectedType &&
                    sku.pricingInfo
            );

            if (matchingSku) {
                startPrice = parseFloat(
                    matchingSku.pricingInfo.lowPrice
                        ? matchingSku.pricingInfo.lowPrice
                        : matchingSku.pricingInfo.marketPrice || 0
                );
            }
        }

        let cashPrice = startPrice;

        if (rules.storeConfig.storeCurrency !== "USD") {
            try {
                cashPrice /= exchangeRate;
            } catch (error) {
                console.error("Error fetching exchange rate:", error);
            }
        }

        if (type === "cash") {
            const cashBuyrate = Number(rules.buylistConfig.cashBuyrate);
            cashPrice = ((startPrice * cashBuyrate) / 100).toFixed(2);
        }

        if (existingCartItemIndex !== -1) {
            const existingCartItem = cartItems[existingCartItemIndex];
            const totalQuantity = existingCartItem.quantity + newQuantity;

            if (totalQuantity > maxQuantity) {
                toast.error(
                    `Cannot add more than ${maxQuantity} cards of the same type and condition.`
                );
                return;
            }

            existingCartItem.quantity = totalQuantity;
            const updatedCartItems = [...cartItems];
            updatedCartItems[existingCartItemIndex] = existingCartItem;
            setCartItems(updatedCartItems);
        } else {
           
            const cartItem = {
                product,
                type,
                quantity: newQuantity,
                selectedType,
                selectedCondition,
                skus: product.skus,
                price: originalCashPrice, // Using cash price without split percentage
            };

            if (cartItem.quantity > maxQuantity) {
                toast.error(
                    `Cannot add more than ${maxQuantity} cards of the same type and condition.`
                );
                return;
            }

            setCartItems([...cartItems, cartItem]);
        }
        setIsAddedToCart(true);

        toast.info(`${product.name} Added to cart!`);
    };


    if (!product.skus || product.skus.length === 0) {
        return null;
    }

    const printingTypes = [
        ...new Set(
            product.skus
                .filter((sku) => sku.pricingInfo)
                .map((sku) => sku.printingName)
        ),
    ];

    const conditionTypes = [
        ...new Set(
            product.skus
                .filter(
                    (sku) =>
                        sku.pricingInfo && sku.printingName === selectedType
                )
                .map((sku) => sku.condAbbr)
        ),
    ];

    const customConditionSort = (a, b) => {
        const conditionOrder = {
            NM: 1,
            LP: 2,
            MP: 3,
            HP: 4,
            DM: 5,
        };

        return (
            (conditionOrder[a.toString().toUpperCase()] || Infinity) -
            (conditionOrder[b.toString().toUpperCase()] || Infinity)
        );
    };

    return (
        <div className={`product-item ${isAddedToCart ? 'added-to-cart' : ''}`}>
            <div className="product-image">
                <Image src={product.image} alt={product.name} />
            </div>

            <div className="product-details">
                <p className="product-name">
                    <Tooltip title={product.name}>
                        {product.name} {product.number ? "(" + product.number + ")" : ""}
                    </Tooltip>
                </p>
                <div className="product-condition">
                    <Select
                        value={selectedCondition}
                        onChange={(value) => setSelectedCondition(value)}
                        style={{ width: "100%" }}
                    >
                        {conditionTypes
                            .sort(customConditionSort)
                            .map((condition) => (
                                <Option key={condition} value={condition}>
                                    {condition}
                                </Option>
                            ))}
                    </Select>
                </div>
                <div className="product-type">
                    <Select
                        value={printingTypes[0]}
                        onChange={handleTypeChange}
                        style={{ width: "100%" }}
                    >
                        {printingTypes.map((printingType) => (
                            <Option key={printingType} value={printingType}>
                                {printingType}
                            </Option>
                        ))}
                    </Select>
                </div>
                <div className="product-price">
                    <InputNumber
                        min={1}
                        value={quantity}
                        style={{ width: "100%" }}
                        onChange={setQuantity}
                    />
                    <Slider
                        value={splitPercentage}
                        onChange={handleSliderChange}
                        style={{ marginTop: '10px' }}
                    />
                    <>
                        <Button

                            style={{
                                margin: "10px 0",
                                width: "100%",
                                color: "white",
                                backgroundColor: rules.storeConfig.cartButtonColor,
                            }}
                        >
                            {`Cash: ${getSymbolFromCurrency(
                                rules.storeConfig.storeCurrency
                            )}${displayedCashPrice}`}
                        </Button>

                        <Button

                            style={{
                                margin: "10px 0",
                                width: "100%",
                                color: "white",
                                backgroundColor: rules.storeConfig.cartButtonColor,
                            }}
                        >
                            {`Credit: ${getSymbolFromCurrency(
                                rules.storeConfig.storeCurrency
                            )}${displayedCreditPrice}`}
                        </Button>
                        <Button
                            icon={<ShoppingCartOutlined />}
                            style={{
                                margin: "10px 0",
                                width: "100%",
                                color: "white",
                                backgroundColor: rules.storeConfig.cartButtonColor,
                            }}
                            type="primary"
                            onClick={() => {
                                
                                handleAddToCart(
                                    product,
                                    "cash",
                                    quantity,
                                    selectedType,
                                    selectedCondition
                                );
                            }}
                        >
                            Add to Cart
                        </Button>
                    </>
                </div>
            </div>
        </div>
    );
};

export default ProductItem;
