import React, { useState, useEffect } from "react";
import { Badge, Button, Image, InputNumber, Select, Input, Tooltip } from "antd";
import {
  CreditCardFilled,
  MoneyCollectFilled,
  DollarCircleFilled,
  ShoppingCartOutlined,
} from "@ant-design/icons";
import "./ProductItem.css";
import { toast } from "react-toastify";
import getSymbolFromCurrency from 'currency-symbol-map'
import axios from "axios";

const { Option } = Select;

const ProductItems = ({
  product,
  addToCart,
  removeCartItem,
  rules,
  searchQuery,
  cartItems,
  setCartItems,
  exchangeRate,
  handleCheckout,
}) => {
  const [quantity, setQuantity] = useState(1);
  const [selectedType, setSelectedType] = useState("");
  const [selectedCondition, setSelectedCondition] = useState("NM");
  const [displayedCashPrice, setDisplayedCashPrice] = useState(0);
  const [displayedCreditPrice, setDisplayedCreditPrice] = useState(0);
  const [customCashPrice, setCustomCashPrice] = useState(0); // Added customCashPrice state
  const [customCreditPrice, setCustomCreditPrice] = useState(0); // Added customCreditPrice state
  const [notification, setNotification] = useState("");

  useEffect(() => {
    if (product.skus && product.skus.length > 0) {
      calculateDisplayedPrice(product, rules, selectedType, selectedCondition, exchangeRate);
    }
  }, [selectedType, selectedCondition, product, rules, exchangeRate]);


  useEffect(() => {
    // Check if the selected type is available; if not, select "Normal" if available.
    const availableTypes = product.skus
      .filter(
        (sku) =>
          sku.pricingInfo &&
          sku.condAbbr === selectedCondition &&
          sku.pricingInfo?.directLowPrice !== null || sku.pricingInfo?.lowPrice !== null
      )
      .map((sku) => sku.printing);
    console.log(availableTypes)
    if (!availableTypes.includes(selectedType)) {
      if (availableTypes.includes("Normal")) {
        setSelectedType("Normal");
      } else if (availableTypes.length > 0) {
        // If "Normal" is not available, set it to the first available type
        setSelectedType(availableTypes[0]);
        console.log(availableTypes[0])
      }
    }
  }, [selectedCondition, product]);



  /**
  * Calculates the displayed price for a product based on its condition and other factors.
  * @param {Object} product - The product information.
  * @param {Object} rules - The rules for pricing and currency conversion.
  * @param {string} type - The type of the product.
  * @param {string} condition - The condition of the product.
  * @param {number} exchangeRate - The exchange rate for currency conversion.
  */
  const calculateDisplayedPrice = async (
    product,
    rules,
    type,
    condition,
    exchangeRate
  ) => {
    let startPrice = null;

    // Determine the start price based on inventory price or SKU information
    if (
      product.inventoryPrice &&
      product.inventoryPrice !== 0.0 &&
      rules.buylistConfig.enableInventoryPrice
    ) {
      startPrice = parseFloat(product.inventoryPrice);
    } else if (product.skus && condition) {
      const matchingSku = product.skus.find(
        (sku) =>
          sku.condAbbr === condition &&
          sku.printing === type &&
          sku.pricingInfo
      );

      if (matchingSku) {
        startPrice = parseFloat(
          matchingSku.pricingInfo.lowPrice
            ? matchingSku.pricingInfo.lowPrice
            : matchingSku.pricingInfo.marketPrice || 0
        );
      }
    }

    // If no start price is found, set displayed prices to zero and return
    if (startPrice === null) {
      setDisplayedCashPrice(0);
      setDisplayedCreditPrice(0);
      return;
    }

    const nmNormalSku = product.skus.find(
      (sku) =>
        sku.condAbbr === "NM" &&
        sku.printing === selectedType &&
        sku.pricingInfo
    );

    if (nmNormalSku) {
      const displayedPrice = startPrice;
      const nmPrice = parseFloat(
        nmNormalSku.pricingInfo.lowPrice
          ? nmNormalSku.pricingInfo.lowPrice
          : nmNormalSku.pricingInfo.marketPrice || 0
      );
      // Apply market manipulation logic based on condition
      if (condition === 'LP') {
        if (nmPrice > startPrice) {
          startPrice = nmPrice * 0.8; // LP price cannot be greater than NM - 20%
        }
      } else if (condition === 'MP') {
        if (nmPrice > startPrice) {
          startPrice = nmPrice * 0.6; // MP price cannot be greater than LP - 40%
        }
      } else if (condition === 'HP' || condition === 'DM') {
        if (nmPrice > startPrice) {
          startPrice = nmPrice * 0.4; // HP/DM price cannot be greater than MP - 60%
        }
      }

      let cashPrice = displayedPrice;
      let creditPrice = displayedPrice;

      // Calculate cash and credit prices based on rules
      if (rules) {
        const cashEnabled = rules.buylistConfig.enableCash;
        const creditEnabled = rules.buylistConfig.enableCredit;

        if (cashEnabled) {
          const cashBuyrate = Number(rules.buylistConfig.cashBuyrate);
          cashPrice = (displayedPrice * cashBuyrate) / 100;
        }

        if (creditEnabled) {
          const creditBuyrate = Number(rules.buylistConfig.creditBuyrate);
          creditPrice = (displayedPrice * creditBuyrate) / 100;
        }
      }

      // Convert prices if the store currency is not USD
      if (rules.storeConfig.storeCurrency !== "USD") {
        try {
          cashPrice = exchangeRate * cashPrice;
          creditPrice = exchangeRate * creditPrice;
          console.log("APPLIED EXCHANGE RATE", exchangeRate)
        } catch (error) {
          console.error("Error fetching exchange rate:", error);
        }
      }

      // Set displayed cash and credit prices
      setDisplayedCashPrice(cashPrice.toFixed(2));
      setDisplayedCreditPrice(creditPrice.toFixed(2));
    }
  };



  const handleTypeChange = (value) => {

    setSelectedType(value);
  };

  const handleAddToCart = (
    product,
    type,
    newQuantity,
    selectedType,
    selectedCondition
  ) => {
    const existingCartItemIndex = cartItems.findIndex(
      (item) =>
        item.product.productId === product.productId &&
        item.type === type &&
        item.selectedType === selectedType &&
        item.selectedCondition === selectedCondition
    );

    let startPrice = null;
    if (product.skus && selectedCondition) {
      const matchingSku = product.skus.find(
        (sku) =>
          sku.condAbbr === selectedCondition &&
          sku.printing === selectedType &&
          sku.pricingInfo
      );
      if (matchingSku) {
        startPrice = parseFloat(
          matchingSku.pricingInfo?.lowPrice
            ? matchingSku.pricingInfo?.lowPrice
            : matchingSku.pricingInfo?.directLowPrice || 0
        );
      }
    }

    let cashPrice = startPrice;
    let creditPrice = startPrice;

    if (rules.storeConfig.storeCurrency !== "USD") {
      try {
        // Use the exchangeRate prop here
        cashPrice /= exchangeRate;
        creditPrice /= exchangeRate;
      } catch (error) {
        console.error("Error fetching exchange rate:", error);
      }
    }

    if (type === "cash") {
      // Use customCashPrice if provided and not blank or 0, else calculate as before
      if (
        customCashPrice !== null &&
        customCashPrice !== "" &&
        customCashPrice !== 0
      ) {
        cashPrice = parseFloat(customCashPrice);
      } else {
        const cashBuyrate = Number(rules.buylistConfig.cashBuyrate);
        cashPrice = ((startPrice * cashBuyrate) / 100).toFixed(2);
      }
    } else {
      // Use customCreditPrice if provided and not blank or 0, else calculate as before
      if (
        customCreditPrice !== null &&
        customCreditPrice !== "" &&
        customCreditPrice !== 0
      ) {
        creditPrice = parseFloat(customCreditPrice);
      } else {
        const creditBuyrate = Number(rules.buylistConfig.creditBuyrate);
        creditPrice = ((startPrice * creditBuyrate) / 100).toFixed(2);
      }
    }

    if (existingCartItemIndex !== -1) {
      const existingCartItem = cartItems[existingCartItemIndex];
      const totalQuantity = existingCartItem.quantity + newQuantity;

      // Check if the total quantity exceeds the maximum allowed
      const maxQuantity = Number(rules.buylistConfig?.maximumQuantity) || 10;
      if (totalQuantity > maxQuantity) {
        toast.error(
          `Cannot add more than ${maxQuantity} cards of the same type and condition.`
        );
        return;
      }

      // Update the quantity of the existing item in the cart
      existingCartItem.quantity = totalQuantity;

      // Create a new array with the updated cart items
      const updatedCartItems = [...cartItems];
      updatedCartItems[existingCartItemIndex] = existingCartItem;

      // Update the cart state with the new array
      setCartItems(updatedCartItems);
    } else {
      // Create a new cart item
      const cartItem = {
        product,
        type,
        quantity: newQuantity,
        selectedType,
        selectedCondition,
        skus: product.skus,
      };
      console.log("PRODUCT", product)
      // Check if the total quantity exceeds the maximum allowed
      const maxQuantity = Number(rules.buylistConfig?.maximumQuantity) || 10;
      if (cartItem.quantity > maxQuantity) {
        toast.error(
          `Cannot add more than ${maxQuantity} cards of the same type and condition.`
        );
        return;
      }

      if (cartItem.type === "cash" && customCashPrice == 0) {
        cartItem.price = displayedCashPrice;
      } else if (cartItem.type === "credit" && customCreditPrice == 0) {
        cartItem.price = displayedCreditPrice;
      }
      if (customCashPrice !== 0) {
        cartItem.price = Number(customCashPrice);
      }
      if (customCreditPrice !== 0) {
        cartItem.price = Number(customCreditPrice);
      }
      // Push the new cart item to the cart
      setCartItems([...cartItems, cartItem]);
    }

    toast.info(`${product.name} Added to cart!`);
  };

  if (!product.skus || product.skus.length === 0) {
    return null; // Do not render the product if skus don't exist or have no pricingInfo
  }

  const printingTypes = [
    ...new Set(
      product.skus
        .filter((sku) => sku.pricingInfo)
        .map((sku) => sku.printing)
    ),
  ];
  const conditionTypes = [
    ...new Set(
      product.skus
        .filter((sku) => sku.pricingInfo)
        .map((sku) => sku.condAbbr)
    ),
  ];

  const customConditionSort = (a, b) => {
    const conditionOrder = {
      "NM": 1,
      "LP": 2,
      "MP": 3,
      "HP": 4,
      "DM": 5,
    };

    const conditionA = a.toString().toUpperCase();
    const conditionB = b.toString().toUpperCase();

    return (conditionOrder[conditionA] || Infinity) - (conditionOrder[conditionB] || Infinity);
  };

  const maxQuantity = Number(rules.buylistConfig?.maximumQuantity) || 10;
  const isNotBuyingCash =
    product.invQuantity > maxQuantity ||
    Number(displayedCashPrice) < rules.buylistConfig?.minimumBuyPrice; // Check if displayedCashPrice is less than minimumBuyPrice
  const isNotBuyingCredit =
    product.invQuantity > maxQuantity ||
    Number(displayedCreditPrice) < rules.buylistConfig?.minimumBuyPrice; // Check if displayedCreditPrice is less than minimumBuyPrice


  return (
    searchQuery.length > 3 &&
    <div className="product-item">
      <div className="product-image">
        <Image src={product.image} alt={product.name} />
      </div>

      <div className="product-details">
        <p className="product-name">
          <Tooltip title={product.name}>
            {product.name}
          </Tooltip>
        </p>
        <p className="product-number">{product.number}</p>
        <p className="product-expansion">{product.expansionName}</p>

        <p className="product-rarity">{product.rarity}</p>
        {notification && <p className="notification">{notification}</p>}
        <div className="product-condition">
          <Select
            value={selectedCondition}
            onChange={(value) => setSelectedCondition(value)}
            style={{ width: "100%" }}
          >
            {conditionTypes
              .filter((condition) =>
                product.skus.some(
                  (sku) =>
                    sku.condAbbr === condition &&
                    sku.printing === selectedType &&
                    sku.pricingInfo
                )
              )
              .sort(customConditionSort)
              .map((condition) => (
                <Option key={condition} value={condition}>
                  {condition}
                </Option>
              ))}
          </Select>
        </div>
        <div className="product-type">
          <Select
            value={selectedType}
            onChange={handleTypeChange}
            style={{ width: "100%" }}
          >
            {printingTypes.map((printingType) => (
              <Option key={printingType} value={printingType}>
                {printingType}
              </Option>
            ))}
          </Select>
        </div>
        <div className="product-price">
          <InputNumber
            min={1}
            max={rules.buylistConfig?.maximumBuyQuantity ? rules.buylistConfig?.maximumBuyQuantity : 8 - product.invQuantity}
            value={quantity}
            style={{ width: "100%" }}
            onChange={setQuantity}
          />
          <a>We currently have {product.invQuantity}</a>
          <>
            <InputNumber
              placeholder="Custom Cash Price"
              value={customCashPrice}
              prefix={"Cash:"}
              onChange={(e) => setCustomCashPrice(Number(e))}
              style={{ marginBottom: 10, width: "100%" }}
            />
            <InputNumber
              placeholder="Custom Credit Price"
              value={customCreditPrice}
              prefix={"Credit:"}
              onChange={(e) => setCustomCreditPrice(Number(e))}
              style={{ marginBottom: 10, width: "100%" }}
            />
            <Button
              onClick={() => {
                if (!isNotBuyingCash) {
                  handleAddToCart(
                    product,
                    "cash",
                    quantity,
                    selectedType,
                    selectedCondition
                  );
                }
              }}
              style={{
                margin: "10px 0",
                width: "100%",
                color: "white",
                backgroundColor: isNotBuyingCash
                  ? rules.storeConfig.cashButtonColor
                  : rules.storeConfig.cartButtonColor,
              }}
              disabled={isNotBuyingCash}
            >
              {isNotBuyingCash
                ? `Not Currently Buying`
                : `Cash: ${getSymbolFromCurrency(rules.storeConfig.storeCurrency)}${customCashPrice || displayedCashPrice}`}
            </Button>

            <Button
              onClick={() => {
                if (!isNotBuyingCredit) {
                  handleAddToCart(
                    product,
                    "credit",
                    quantity,
                    selectedType,
                    selectedCondition
                  );
                }
              }}
              style={{
                margin: "10px 0",
                width: "100%",
                color: "white",
                backgroundColor: isNotBuyingCash
                  ? rules.storeConfig.creditButtonColor
                  : rules.storeConfig.cartButtonColor,
              }}
              disabled={isNotBuyingCredit}
            >
              {isNotBuyingCredit
                ? `Not Currently Buying`
                : `Credit: ${getSymbolFromCurrency(rules.storeConfig.storeCurrency)}${customCreditPrice || displayedCreditPrice}`}
            </Button>
            <Button
              icon={<ShoppingCartOutlined />}
              style={{
                margin: "10px 0",
                width: "100%",
                color: "white",
                backgroundColor: rules.storeConfig.cartButtonColor
              }}
              type="primary"
              onClick={handleCheckout}
            >
              View Cart
            </Button>
          </>
        </div>
      </div>
    </div>
  );
};

export default ProductItems;