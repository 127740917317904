import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Pagination,
  Input,
  Space,
  Select,
  Drawer,
  Table,
  Radio,
  Checkbox,
  Result,
  InputNumber,
  Tooltip,
  Slider,
  Modal,
} from "antd";
import "./MainDisplay.css";
import chalk from "chalk";
import ProductItem from "./ProductItem";
import {
  getProductsForGame,
  getStoreSets,
  getTMT,
  placeOrder,
} from "../handler/APIService";
import LoadingOverlay from "react-loading-overlay";
import {
  ImportOutlined,
  OrderedListOutlined,
  ScanOutlined,
  ShoppingCartOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import getSymbolFromCurrency from "currency-symbol-map";
import ProductsItems from "./ProductsItems";
import ProductItemMix from "./ProductItemMix";
import CardScanModal from "./CardScanModal";
import axios from "axios";
import CSVModal from "./CSVModal.js";
import DeckModal from "./DeckModal";
import MostWantedCarousel from "./MostWanted";

const { Option } = Select;
const { Search } = Input;

const SelectItemsModal = ({ visible, onClose, items, onAddItems }) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedKeys) => {
      setSelectedRowKeys(selectedKeys);
    },
  };

  const handleAddSelectedItems = () => {
    console.log("Adding selected items:", selectedRowKeys); // Log on add
    const selectedItems = items.filter((item) =>
      selectedRowKeys.includes(item._id)
    );
    onAddItems(selectedItems);
    onClose();
  };

  const columns = [
    { title: "Name", dataIndex: "name", key: "name" },
    { title: "Price", dataIndex: "price", key: "price" },
    { title: "Quantity", dataIndex: "quantity", key: "quantity" },
    { title: "Condition", dataIndex: "condition", key: "condition" },
  ];

  return (
    <Modal
      title="Select Items to Add to Cart"
      visible={visible}
      onOk={handleAddSelectedItems}
      onCancel={onClose}
      width={800}
    >
      <Table
        rowSelection={rowSelection}
        columns={columns}
        dataSource={items}
        rowKey={(record) => record._id}
        pagination={false}
      />
      <Button
        onClick={() => setSelectedRowKeys(items.map((item) => item._id))} // For selecting all items
        style={{ margin: "10px 0" }}
      >
        Select All
      </Button>
    </Modal>
  );
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const gameOptions = {
  1: "Magic: The Gathering",
  2: "YuGiOh",
  3: "Pokemon",
  4: "Axis & Allies",
  5: "Boardgames",
  6: "D & D Miniatures",
  7: "Epic",
  8: "Heroclix",
  9: "Monsterpocalypse",
  10: "Redakai",
  11: "Star Wars Miniatures",
  12: "World of Warcraft Miniatures",
  13: "WoW",
  14: "Supplies",
  15: "Organizers and Stores",
  16: "Cardfight Vanguard",
  17: "Force of Will",
  18: "Dice Masters",
  19: "Future Card BuddyFight",
  20: "Weiss Schwarz",
  21: "My Little Pony",
  22: "TCGplayer",
  23: "Dragon Ball Z TCG",
  24: "Final Fantasy TCG",
  25: "UniVersus",
  26: "Star Wars: Destiny",
  27: "Dragon Ball Super CCG",
  28: "Dragoborne",
  29: "Funko",
  30: "MetaX TCG",
  31: "Card Sleeves",
  32: "Deck Boxes",
  33: "Card Storage Tins",
  34: "Life Counters",
  35: "Playmats",
  36: "Zombie World Order TCG",
  37: "The Caster Chronicles",
  38: "My Little Pony CCG",
  39: "Warhammer Books",
  40: "Warhammer Big Box Games",
  41: "Warhammer Box Sets",
  42: "Warhammer Clampacks",
  43: "Citadel Paints",
  44: "Citadel Tools",
  45: "Warhammer Game Accessories",
  46: "Books",
  47: "Exodus TCG",
  48: "Lightseekers TCG",
  49: "Protective Pages",
  50: "Storage Albums",
  51: "Collectible Storage",
  52: "Supply Bundles",
  53: "Munchkin CCG",
  54: "Warhammer Age of Sigmar Champions TCG",
  55: "Architect TCG",
  56: "Bulk Lots",
  57: "Transformers TCG",
  58: "Bakugan TCG",
  59: "KeyForge",
  60: "Chrono Clash System",
  61: "Argent Saga TCG",
  62: "Flesh and Blood TCG",
  63: "Digimon Card Game",
  64: "Alternate Souls",
  65: "Gate Ruler",
  66: "MetaZoo",
  67: "WIXOSS",
  68: "One Piece Card Game",
  69: "Marvel Comics",
  70: "DC Comics",
  71: "Disney Lorcana",
  72: "Battle Spirits Saga",
  73: "Shadowverse",
  74: "Grand Archive TCG",
  75: "Akora TCG",
  76: "Kryptik TCG",
  77: "Sorcery: Contested Realm",
  80: "Dragon Ball Super Fusion World",
  88: "Video Games",
  79: "Star Wars Unlimited",
};

const MainDisplay = ({ storeInfo, log, checkoutMode, mixMode }) => {
  const [selectedGame, setSelectedGame] = useState();
  const [selectedGameProducts, setSelectedGameProducts] = useState([]);
  const [selectedGameSets, setSelectedGameSets] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [exchangeRate, setExchangeRate] = useState(1);
  const [selectedExpansionFilters, setSelectedExpansionFilters] = useState([]);
  const [selectedRarityFilters, setSelectedRarityFilters] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [cartItems, setCartItems] = useState([]);
  const [cartVisible, setCartVisible] = useState(false);
  const [checkoutVisible, setCheckoutVisible] = useState(false);
  const [fulfillmentMethod, setFulfillmentMethod] = useState("store-drop-in");
  const [pageSize, setPageSize] = useState(
    storeInfo.storeConfig.pageSize ? storeInfo.storeConfig.pageSize : 16
  );
  const [pageCount, setPageCount] = useState(0);
  const [pCount, setpCount] = useState(0);
  const [setCode, setSetCode] = useState("");
  const [bulkQuantities, setBulkQuantities] = useState({});
  const [staffMember, setStaffMember] = useState("");
  const [splitPercentage, setSplitPercentage] = useState(100);
  const [customerDetails, setCustomerDetails] = useState({
    name: "",
    address: "",
    paymentMethod: "",
  });
  const [isCustomItemModalVisible, setCustomItemModalVisible] = useState(false);
  const [customItemDetails, setCustomItemDetails] = useState({
    name: "",
    description: "",
    quantity: 1,
    price: 0,
    condition: "NM", // Default or selectable based on your design
    type: "cash", // Default or selectable
  });
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isTMTModalVisible, setIsTMTModalVisible] = useState(false);
  const [fetchedItems, setFetchedItems] = useState([]);

  const [tmtId, setTmtId] = useState("");
  const [tmtPin, setTmtPin] = useState("");

  const showModal = () => {
    setIsTMTModalVisible(true);
    setTmtId(""); // Reset on modal open if you want it always empty initially
    setTmtPin(""); // Reset on modal open if you want it always empty initially
  };

  const handleOk = async () => {
    try {
      // Fetch items from TMT
      const items = await getTMT(tmtId, tmtPin);
      if (!items || Object.keys(items).length === 0) {
        toast.error("No items fetched or invalid response.");
        return;
      }

      // Iterate over each item and add to cart
      Object.values(items).forEach((item) => {
        const newItem = {
          product: {
            _id: item._id,
            name: item.name,
            description: `Rarity: ${item.rarity}, Condition: ${item.condition}`,
            rarity: item.rarity,
            skus: [
              {
                skuId: item.skuId,
                price: item.price,
                condition: item.condition,
                language: item.language,
                printing: item.printing,
              },
            ],
            url:
              item.url ||
              `https://www.ebay.com/sch/i.html?_nkw=${encodeURIComponent(
                item.name
              )}&_in_kw=1&_ex_kw=&_sacat=0&LH_Sold=1&LH_Complete=1&_udlo=&_udhi=&_samilow=&_samihi=&_sadis=15&_stpos=&_sargn=-1%26saslc%3D1&_salic=1&_sop=10&_dmd=1&_ipg=50&LH_PrefLoc=1`,
            image: item.image || "https://via.placeholder.com/150",
          },
          quantity: item.quantity,
          selectedCondition: item.condition,
          condition: item.condition,
          selectedType: item.printing,
          price: item.price,
          type: item.printing,
          name: item.name,
          description: `Rarity: ${item.rarity}, Condition: ${item.condition}`,
          rarity: item.rarity,
        };

        // Update the cart items state
        setFetchedItems((cartItems) => [...cartItems, newItem]);
      });
      setIsTMTModalVisible(false);
      setIsModalVisible(true);

      resetInputs(); // Reset inputs if you have a function to clear form states
    } catch (error) {
      console.error("Error fetching or processing items:", error);
      toast.error("An error occurred.");
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    resetInputs(); // Reset inputs when modal is cancelled
  };

  const resetInputs = () => {
    setTmtId("");
    setTmtPin("");
  };
  // Update handleCustomItemDetailChange to handle all fields
  const handleCustomItemDetailChange = (key, value) => {
    setCustomItemDetails({ ...customItemDetails, [key]: value });
  };

  // Modify addCustomItemToCart to construct the full custom item object
  const addCustomItemToCart = () => {
    const newItem = {
      product: {
        _id: Math.random().toString(36).substr(2, 9),
        name: customItemDetails.name,
        description: customItemDetails.description,
        rarity: "Custom Item",
        skus: [],
        url: `https://www.ebay.com/sch/i.html?_nkw=${encodeURIComponent(
          customItemDetails.name
        )}&_in_kw=1&_ex_kw=&_sacat=0&LH_Sold=1&LH_Complete=1&_udlo=&_udhi=&_samilow=&_samihi=&_sadis=15&_stpos=&_sargn=-1%26saslc%3D1&_salic=1&_sop=10&_dmd=1&_ipg=50&LH_PrefLoc=1`,
        image:
          "https://media.discordapp.net/attachments/1172181781764649012/1214214338210955355/0_1.jpg?ex=65f84c39&is=65e5d739&hm=afb2c332c20809a963b977afb5b1fff23a2fc51a9bb69e4d8c1202128e6052f5&=&format=webp&width=670&height=670",
      },
      quantity: customItemDetails.quantity,
      selectedCondition: customItemDetails.condition,
      selectedType: customItemDetails.type,
      price: customItemDetails.price,
      type: customItemDetails.type,
    };
    toast.info(customItemDetails.name + " added to cart.");
    setCartItems([...cartItems, newItem]);
    setCustomItemModalVisible(false); // Close the modal after adding
  };

  useEffect(() => {
    const savedCart = localStorage.getItem("cartItems");
    if (savedCart) {
      setCartItems(JSON.parse(savedCart));
    }
  }, []);

  useEffect(() => {
    if (cartItems.length > 0) {
      localStorage.setItem("cartItems", JSON.stringify(cartItems));
    } else {
      localStorage.removeItem("cartItems");
    }
    async function fetchExchangeRate() {
      if (exchangeRate == 1) {
        try {
          const response = await axios.get(
            "https://server1.tcgs.xyz:4000/api/test/buylist_exchange?currencyCode=" +
              storeInfo.storeConfig.storeCurrency
          );
          const rate = response.data.data.usdToUserRate;
          console.log(rate);
          const rate2 = response.data.data.eurToUserRate;
          setExchangeRate(rate);
        } catch (error) {
          console.error("Error fetching exchange rate:", error);
        }
      }
    }
    //1.5317 294.98
    fetchExchangeRate();
  }, [cartItems]);

  useEffect(() => {
    // Initialize the bulk quantities based on the available bulk prices
    const initialBulkQuantities = {};
    if (storeInfo.storeConfig?.bulkPrices) {
      Object.keys(storeInfo.storeConfig.bulkPrices).forEach((gameId) => {
        storeInfo.storeConfig.bulkPrices[gameId].forEach((bulkPrice) => {
          initialBulkQuantities[bulkPrice.condition] = 0; // Initialize quantities to 0
        });
      });
      setBulkQuantities(initialBulkQuantities);
    }
  }, [storeInfo]);

  const abortController = useRef(new AbortController());

  const handleGameSelection = (value) => {
    setSelectedGame(value);
    setSearchQuery("");
    abortController.current.abort();
    abortController.current = new AbortController();
    handleGetSet(value, abortController.current.signal);
  };

  const handleGetSet = async (gameName, signal) => {
    log(chalk.green("Pulling sets for:"), gameName);
    setLoading(true);
    try {
      const sets = await getStoreSets(gameName, signal);
      if (sets) {
        setSelectedGameSets(sets.sort((a, b) => a.name.localeCompare(b.name)));
        log(chalk.green("Sets for " + gameName + ":"), sets);
        setLoading(false);
      }
    } catch (error) {
      if (error.name !== "AbortError") {
        console.error("Error fetching sets:", error);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleGetProducts = async (
    gameName,
    sets,
    page,
    pageSize,
    sortCriteria,
    signal
  ) => {
    log(chalk.green("Pulling products for:"), selectedGame);
    setLoading(true);
    try {
      const products = await getProductsForGame(
        storeInfo.storeOwner,
        gameName,
        sets,
        page,
        pageSize,
        searchQuery,
        setCode,
        sortCriteria,
        selectedRarityFilters,
        signal
      );
      if (products.success === true) {
        setSelectedGameProducts(products.products);
        setPageCount(products.totalPages);

        setpCount(products.totalCount);
        log(chalk.green("Products for " + gameName + ":"), products);
      }
    } catch (error) {
      if (error.name !== "AbortError") {
        console.error("Error fetching products:", error);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedGame) {
      handleGameSelection(selectedGame);
    }
  }, [selectedGame]);

  const debounceTimeout = useRef(null);

  useEffect(() => {
    // Clear the debounce timeout on component unmount
    return () => {
      clearTimeout(debounceTimeout.current);
    };
  }, []);

  // Debounced function to handle triggering handleGetProducts
  const debouncedHandleGetProducts = () => {
    // Clear previous debounce timeout
    clearTimeout(debounceTimeout.current);
    // Set a new debounce timeout of 1.5 seconds
    debounceTimeout.current = setTimeout(() => {
      setLoading(true);
      handleGetProducts(
        selectedGame,
        selectedExpansionFilters,
        currentPage,
        pageSize,
        "sortCriteria",
        selectedRarityFilters,
        abortController.current.signal
      );
    }, 500);
  };

  useEffect(() => {
    // Trigger handleGetProducts after 1.5 seconds of no change in searchQuery
    debouncedHandleGetProducts();

    // Clean up function to reset debounce on searchQuery change
    const cleanup = () => {
      clearTimeout(debounceTimeout.current);
      // Reset debounce timeout
      debounceTimeout.current = null;
    };

    // Cleanup debounce timeout if searchQuery changes before 1.5 seconds
    if (debounceTimeout.current !== null) {
      cleanup();
    }

    // Set a new debounce timeout
    debounceTimeout.current = setTimeout(() => {
      debouncedHandleGetProducts();
    }, 500);

    // Cleanup function to clear debounce timeout on component unmount or searchQuery change
    return cleanup;
  }, [selectedExpansionFilters, searchQuery, selectedRarityFilters]);

  useEffect(() => {
    handleGetProducts(
      selectedGame,
      selectedExpansionFilters,
      currentPage,
      pageSize,
      "sortCriteria",
      selectedRarityFilters,
      abortController.current.signal
    );
  }, [selectedGame, currentPage, pageSize]);

  const enabledGameOptions = Object.entries(storeInfo.storeConfig.enabledGames)
    .filter(([gameId, isEnabled]) => isEnabled)
    .map(([gameId, gameName]) => ({
      gameId,
      gameName,
    }));

  const gameSelectOptions = enabledGameOptions.map(({ gameId, gameName }) => (
    <Option key={Number(gameName)} value={gameName}>
      {gameOptions[Number(gameName)]}
    </Option>
  ));

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleTypeChange = (key, value) => {
    const updatedCartItems = cartItems.map(async (item, index) => {
      if (index === key.key) {
        const newPrice = await calculateDisplayedPrice(
          item.product,
          storeInfo,
          value,
          item.selectedCondition,
          exchangeRate
        );

        return {
          ...item,
          selectedType: value,
          price: newPrice.cashPrice,
        };
      }
      return item;
    });
    Promise.all(updatedCartItems).then((updatedItems) => {
      setCartItems(updatedItems);
    });
  };

  const handleConditionChange = (key, value) => {
    const updatedCartItems = cartItems.map(async (item, index) => {
      if (index === key.key) {
        const newPrice = await calculateDisplayedPrice(
          item.product,
          storeInfo,
          item.selectedType,
          value,
          exchangeRate
        );

        return {
          ...item,
          selectedCondition: value,
          price: newPrice.cashPrice,
        };
      }
      return item;
    });
    Promise.all(updatedCartItems).then((updatedItems) => {
      setCartItems(updatedItems);
    });
  };

  const calculateDisplayedPrice = async (
    product,
    rules,
    type,
    condition,
    exchangeRate
  ) => {
    let startPrice = null;

    // Determine the start price based on inventory price or SKU information
    if (
      product.inventoryPrice &&
      product.inventoryPrice !== 0.0 &&
      rules.buylistConfig.enableInventoryPrice
    ) {
      startPrice = parseFloat(product.inventoryPrice);
      console.log(`Using inventory price as start price: ${startPrice}`);
    } else if (product.skus && condition) {
      const matchingSku = product.skus.find(
        (sku) =>
          sku.condAbbr === condition &&
          sku.printingName === type &&
          sku.pricingInfo
      );

      if (matchingSku) {
        startPrice = parseFloat(
          matchingSku.pricingInfo.lowPrice
            ? matchingSku.pricingInfo.lowPrice
            : 0
        );
        console.log(
          `Using ${condition} ${type} SKU price as start price: ${startPrice}`
        );
      }
    }

    // If no start price is found, set displayed prices to zero and return
    if (startPrice === null || isNaN(startPrice)) {
      console.log(
        "No valid start price found, setting displayed prices to zero."
      );
      return {
        cashPrice: 0,
        creditPrice: 0,
      };
    }

    const nmNormalSku = product.skus.find(
      (sku) =>
        sku.condAbbr === "NM" && sku.printingName === type && sku.pricingInfo
    );

    if (nmNormalSku) {
      console.log("Got Near Mint SKU", nmNormalSku);
      let displayedPrice = startPrice;
      const nmPrice = parseFloat(
        nmNormalSku.pricingInfo.lowPrice ? nmNormalSku.pricingInfo.lowPrice : 0
      );
      console.log(`NM ${type} SKU price: ${nmPrice}`);

      // Apply market manipulation logic based on condition
      if (condition === "LP") {
        if (startPrice > nmPrice) {
          startPrice = nmPrice * 0.8; // LP price cannot be greater than NM - 20%
          displayedPrice = startPrice;
          console.log(`Adjusted LP price based on NM: ${startPrice}`);
        }
      } else if (condition === "MP") {
        if (startPrice > nmPrice) {
          startPrice = nmPrice * 0.6; // MP price cannot be greater than LP - 40%
          displayedPrice = startPrice;
          console.log(`Adjusted MP price based on NM: ${startPrice}`);
        }
      } else if (condition === "HP" || condition === "DM") {
        if (startPrice > nmPrice) {
          startPrice = nmPrice * 0.4; // HP/DM price cannot be greater than MP - 60%
          displayedPrice = startPrice;
          console.log(`Adjusted ${condition} price based on NM: ${startPrice}`);
        }
      }

      let cashPrice = displayedPrice;
      let creditPrice = displayedPrice;

      // Calculate cash and credit prices based on rules
      if (rules) {
        const cashEnabled = rules.buylistConfig.enableCash;
        const creditEnabled = rules.buylistConfig.enableCredit;

        if (cashEnabled) {
          const cashBuyrate = Number(rules.buylistConfig.cashBuyrate);
          cashPrice = (displayedPrice * cashBuyrate) / 100;
          console.log(`Cash price: ${cashPrice}`);
        }

        if (creditEnabled) {
          const creditBuyrate = Number(rules.buylistConfig.creditBuyrate);
          creditPrice = (displayedPrice * creditBuyrate) / 100;
          console.log(`Credit price: ${creditPrice}`);
        }
      }

      // Convert prices if the store currency is not USD
      if (rules.storeConfig.storeCurrency !== "USD") {
        try {
          cashPrice = exchangeRate * cashPrice;
          creditPrice = exchangeRate * creditPrice;
          console.log(`APPLIED EXCHANGE RATE: ${exchangeRate}`);
        } catch (error) {
          console.error("Error fetching exchange rate:", error);
        }
      }

      return {
        cashPrice: cashPrice,
        creditPrice: creditPrice,
      };
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "productName",
      key: "productName",
    },
    {
      title: "Set",
      dataIndex: "abbreviation",
      key: "abbreviation",
      render: (text, record) => (
        <Tooltip title={record.expansionName || text}>{text}</Tooltip>
      ),
    },
    {
      title: "#",
      dataIndex: "number",
      key: "number",
    },
    {
      title: "Qty",
      dataIndex: "quantity",
      key: "quantity",
      render: (text, record) => (
        <Input
          style={{ width: "50px" }}
          type="number"
          max={storeInfo.buylistConfig.maximumBuyQuantity - record.quantity}
          value={record.quantity}
          onChange={(e) => handleQuantityChange(e.target.value, record.key)}
          min={1}
        />
      ),
    },
    {
      title: "Type",
      dataIndex: "selectedType",
      key: "selectedType",
      render: (selectedType, record) => {
        const printingTypes = getPrintingTypes(record);
        return (
          <Select
            value={selectedType}
            onChange={(e) => handleTypeChange(record, e)}
          >
            {printingTypes.map((type, index) => (
              <Option key={index} value={type}>
                {type}
              </Option>
            ))}
          </Select>
        );
      },
    },
    {
      title: "Condition",
      dataIndex: "condition",
      key: "condition",
      render: (condition, record) => {
        const conditionTypes = getConditionTypes(record, record.selectedType);
        return (
          <Select
            value={condition}
            onChange={(e) => handleConditionChange(record, e)}
          >
            {conditionTypes.map((cond, index) => (
              <Option key={index} value={cond}>
                {cond}
              </Option>
            ))}
          </Select>
        );
      },
    },
    {
      title: "Payment",
      dataIndex: "payment",
      key: "payment",
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (text, record) => (
        <a>
          {getSymbolFromCurrency(storeInfo.storeConfig.storeCurrency)}
          {Number(record.price)}/
          {getSymbolFromCurrency(storeInfo.storeConfig.storeCurrency)}
          {(Number(record.price) * Number(record.quantity)).toFixed(3)}
        </a>
      ),
    },
    {
      title: "Remove",
      dataIndex: "remove",
      key: "remove",
      render: (_, record) => (
        <Button type="link" onClick={() => removeCartItem(record.key)} danger>
          Remove
        </Button>
      ),
    },
  ];

  const handleQuantityChange = (value, productIndex) => {
    const updatedCartItems = [...cartItems];
    const productToUpdate = updatedCartItems[productIndex];

    if (productToUpdate) {
      const newQuantity = parseInt(value, 10);
      if (
        !isNaN(newQuantity) &&
        newQuantity >= 1 &&
        newQuantity <= storeInfo.buylistConfig?.maximumBuyQuantity
          ? storeInfo.buylistConfig?.maximumBuyQuantity
          : 8
      ) {
        productToUpdate.quantity = newQuantity;
      } else {
        console.error("Invalid quantity value:", value);
      }

      setCartItems(updatedCartItems);
    } else {
      console.error("Product not found in cart.");
    }
  };
  const [bulkPriceType, setBulkPriceType] = useState("cash");
  const handleBulkQuantityChange = (gameId, condition, quantity) => {
    setBulkQuantities((prevQuantities) => ({
      ...prevQuantities,
      [gameId]: {
        ...prevQuantities[gameId],
        [condition]: Number(quantity),
      },
    }));
    console.log(gameId, condition, quantity);
    console.log(bulkQuantities);
  };

  const handleSelectBulkPriceType = (type) => {
    setBulkPriceType(type);
  };

  const handleCheckout = () => {
    setCartVisible(true);
  };

  const handleGoToCheckout = () => {
    if (staffMember == "" && checkoutMode == true) {
      toast.error("Please enter the staff member's name.");
      return;
    }

    setCartVisible(false);
    setCheckoutVisible(true);
  };

  const [orderPlaced, setOrderPlaced] = useState(false);
  const [orderDetails, setOrderDetails] = useState(null);
  const [confirmed, setConfirmed] = useState(false);
  const [cardScanModalVisible, setCardScanModalVisible] = useState(false);
  const [CSVModalVisible, setCSVModalVisible] = useState(false);
  const [DeckModalVisible, setDeckModalVisible] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  // Function to open the card scan modal
  const openCardScanModal = () => {
    setCardScanModalVisible(true);
  };

  // Function to close the card scan modal
  const closeCardScanModal = () => {
    setCardScanModalVisible(false);
  };

  // Function to open the card scan modal
  const openCSVModal = () => {
    setCSVModalVisible(true);
  };

  // Function to close the card scan modal
  const closeCSVModal = () => {
    setCSVModalVisible(false);
  };

  // Function to open the card scan modal
  const openDeckModal = () => {
    setDeckModalVisible(true);
  };

  // Function to close the card scan modal
  const closeDeckModal = () => {
    setDeckModalVisible(false);
  };

  const handlePlaceOrder = async () => {
    // Disable the button to prevent spamming
    setButtonDisabled(true);

    if (!confirmed) {
      toast.error(
        "Please confirm that you have read and agreed to the instructions."
      );
      setButtonDisabled(false); // Enable the button back
      return;
    }

    if (cartItems.length === 0) {
      toast.error(
        "Your cart is empty. Add items to the cart before placing an order."
      );
      setButtonDisabled(false); // Enable the button back
      return;
    }

    if (!customerDetails.email || !isValidEmail(customerDetails.email)) {
      toast.error("Please provide a valid email address.");
      setButtonDisabled(false); // Enable the button back
      return;
    }

    if (fulfillmentMethod === "store-drop-in") {
      if (
        !customerDetails.name ||
        !customerDetails.email ||
        !customerDetails.phone
      ) {
        toast.error("Please fill in your Name, Email, and Phone Number.");
        setButtonDisabled(false); // Enable the button back
        return;
      }
    } else if (fulfillmentMethod === "delivery") {
      if (
        !customerDetails.name ||
        !customerDetails.email ||
        !customerDetails.phone
      ) {
        toast.error(
          "Please fill in your Name, Email, Phone Number, and Address."
        );
        setButtonDisabled(false); // Enable the button back
        return;
      }
    }

    const orderData = {
      cartItems: cartItems,
      bulkQuantities: bulkQuantities, // Include bulk quantities in the order data
      bulkPriceType: bulkPriceType,
      customerDetails: {
        name: customerDetails.name,
        email: customerDetails.email,
        phone: customerDetails.phone,
        address: customerDetails.address,
        notes: customerDetails.notes,
      },
      fulfillmentMethod: fulfillmentMethod,
      storeInfo: storeInfo,
    };

    const placedOrder = await placeOrder(orderData, staffMember);

    if (placedOrder) {
      setOrderDetails(placedOrder);
      setCartItems([]);
      setCartVisible(false);
      setCheckoutVisible(false);
      setOrderPlaced(true);
    }

    // Re-enable the button after order placement process is completed
    setButtonDisabled(false);
  };

  const addToCart = (product) => {
    // Check if an item with the same attributes already exists in the cart
    const existingItemIndex = cartItems.findIndex(
      (item) =>
        item.product._id === product.product._id &&
        item.product.productId === product.product.productId &&
        item.selectedCondition === product.selectedCondition &&
        item.type === product.type
    );

    if (existingItemIndex !== -1) {
      // If item exists, increase its quantity
      const updatedCartItems = [...cartItems];
      updatedCartItems[existingItemIndex].quantity += product.quantity;
      setCartItems(updatedCartItems);
    } else {
      // If item doesn't exist, add it as a new entry with a new random _id
      const newItem = {
        ...product,
        product: {
          ...product.product,
          _id: Math.random().toString(36).substr(2, 9),
        },
      };
      setCartItems([...cartItems, newItem]);
    }
  };

  const removeCartItem = (index) => {
    const updatedCartItems = [...cartItems];
    updatedCartItems.splice(index, 1);
    setCartItems(updatedCartItems);
  };

  const onSearch = (value, _e, info) => {
    setSearchQuery(value);
    setCurrentPage(1);
  };

  const handleRarityFilterChange = (value) => {
    setSelectedRarityFilters(value);
  };

  const [rarityFilterOptions, setRarityFilterOptions] = useState([]);

  useEffect(() => {
    const fetchRarities = async () => {
      try {
        // Make the API call with the selected game category ID
        const response = await axios.get(
          `https://api.tcgsync.com:4000/api/test/getUnqiueRarity?categoryId=${selectedGame}`
        );

        // Extract unique rarities from the response data
        const uniqueRarities = response.data.data;

        setRarityFilterOptions(Array.from(uniqueRarities));
      } catch (error) {
        console.error("Error fetching unique rarities:", error);
      }
    };

    fetchRarities();
  }, [selectedGame]);

  const getSortedProducts = (products) => {
    return products;
  };

  const sortedAndFilteredProducts = getSortedProducts(selectedGameProducts);

  const resultStyle = {
    position: "fixed", // Fullscreen overlay
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    zIndex: 1000, // Ensure it's above all other content
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(255, 255, 255, 0.9)", // Semi-transparent white background
  };

  const calculateTotalCartValue = () => {
    if (!Array.isArray(cartItems) || cartItems.length === 0) {
      return 0;
    }

    try {
      return cartItems.reduce((total, item) => {
        if (item.type === "cash") {
          return (
            Number(total) +
            Number(item.price) * Number(item.quantity)
          ).toFixed(2);
        } else {
          return total;
        }
      }, 0);
    } catch (error) {
      console.error("Error calculating total cart value:", error);
      return 0;
    }
  };

  const calculateTotalCreditCartValue = () => {
    if (!Array.isArray(cartItems) || cartItems.length === 0) {
      return 0;
    }

    try {
      return cartItems.reduce((total, item) => {
        if (item.type === "credit") {
          return (
            Number(total) +
            Number(item.price) * Number(item.quantity)
          ).toFixed(2);
        } else {
          return total;
        }
      }, 0);
    } catch (error) {
      console.error("Error calculating total credit cart value:", error);
      return 0;
    }
  };

  const calculateTotalCombinedCartValue = (gameId) => {
    if (!Array.isArray(cartItems) || cartItems.length === 0) {
      return 0;
    }

    try {
      return cartItems.reduce((total, item) => {
        return (
          Number(total) +
          Number(item.price) * Number(item.quantity)
        ).toFixed(2);
      }, 0);
    } catch (error) {
      console.error("Error calculating total credit cart value:", error);
      return 0;
    }
  };

  const isValidEmail = (email) => {
    // Your email validation logic here...
    return /^[\w-]+(\.[\w-]+)*@[\w-]+(\.[\w-]+)+$/.test(email);
  };

  const calculateTotalOrderValue = () => {
    const cashTotal = cartItems.reduce((total, item) => {
      const itemPrice = item.price * item.quantity;
      if (item.type === "Cash") {
        return total + itemPrice;
      }
      return total;
    }, 0);

    const creditTotal = cartItems.reduce((total, item) => {
      const itemPrice = item.price * item.quantity;
      if (item.type === "Credit") {
        return total + itemPrice;
      }
      return total;
    }, 0);

    return {
      cashTotal,
      creditTotal,
      combinedTotal: cashTotal + creditTotal,
    };
  };

  const totalOrderValue = calculateTotalOrderValue();

  const calculateTotalBulkValue = (gameId) => {
    if (
      !storeInfo?.storeConfig?.bulkPrices ||
      !bulkQuantities ||
      !bulkQuantities[gameId]
    ) {
      return 0;
    }

    const bulkPrices = storeInfo.storeConfig.bulkPrices[gameId];
    const bulkQuantitiesForGame = bulkQuantities[gameId];

    let totalBulkValue = 0;
    console.log("ya", bulkQuantitiesForGame);
    bulkPrices.forEach((bulkPrice) => {
      console.log(bulkPrice.condition);
      console.log("HERE", bulkQuantitiesForGame?.[bulkPrice.condition]);
      if (bulkQuantitiesForGame?.[bulkPrice.condition] !== undefined) {
        const quantity =
          Number(
            bulkQuantitiesForGame?.[bulkPrice.condition] == "card"
              ? 1
              : bulkQuantitiesForGame?.[bulkPrice.condition]
          ) || 0;
        console.log(quantity);
        if (bulkPrice.quantityLabel == "card") {
          bulkPrice.quantityLabel = 1;
        }
        if (bulkPrice.quantityLabel !== "card") {
          totalBulkValue += Number(quantity) * Number(bulkPrice.price);
          console.log(bulkPrice.price, bulkPrice.quantityLabel);
        }
      }
    });

    return Number(totalBulkValue);
  };

  const [bulkRatesVisible, setBulkRatesVisible] = useState(false);

  // Function to toggle the visibility of the bulk rates section
  const toggleBulkRatesVisibility = () => {
    setBulkRatesVisible(!bulkRatesVisible);
  };

  const roundToNearest10 = (value) => {
    return Math.floor(value / 10) * 10;
  };
  const handleGetPrice = () => {
    const url = `https://www.ebay.com/sch/i.html?_nkw=${encodeURIComponent(
      customItemDetails.name
    )}&_in_kw=1&_ex_kw=&_sacat=0&LH_Sold=1&LH_Complete=1&_udlo=&_udhi=&_samilow=&_samihi=&_sadis=15&_stpos=&_sargn=-1%26saslc%3D1&_salic=1&_sop=10&_dmd=1&_ipg=50&LH_PrefLoc=1`;
    window.open(url, "_blank");
  };

  const getPrintingTypes = (item) => {
    if (item?.skus) {
      return [
        ...new Set(
          item.skus
            .filter((sku) => sku.pricingInfo)
            .map((sku) => sku.printingName)
        ),
      ];
    } else {
      return [];
    }
  };

  const getConditionTypes = (item, selectedType) => {
    if (item?.skus) {
      const conditionTypes = [
        ...new Set(
          item.skus
            .filter(
              (sku) => sku.pricingInfo && sku.printingName === selectedType
            )
            .map((sku) => sku.condAbbr)
        ),
      ];
      // Manually organize the condition types
      const orderedConditionTypes = ["NM", "LP", "MP", "HP", "DM"];

      // Sort the condition types based on the custom order
      const sortedConditionTypes = orderedConditionTypes.filter((cond) =>
        conditionTypes.includes(cond)
      );

      return sortedConditionTypes;
    } else {
      return [];
    }
  };

  const handleSliderChange = (value) => {
    setSplitPercentage(value);
  };

  const handleAddItemsToCart = (selectedItems) => {
    setCartItems([...cartItems, ...selectedItems]);
    toast.info(`${selectedItems.length} items added to cart.`);
  };

  return (
    <LoadingOverlay
      active={loading}
      spinner
      style={{ width: "100%", height: "100vh" }}
      text="Loading results..."
    >
      <ToastContainer />
      <div className="main-display">
        <>
          <Button
            icon={<ScanOutlined />}
            style={{
              color: "white",
              backgroundColor: storeInfo.storeConfig.cartButtonColor,
            }}
            type="primary"
            onClick={openCardScanModal}
          >
            Scan Cards
          </Button>
          <Tooltip title="Insert your TMT ID and TMT PIN to import from Track My TCG">
            <Button
              icon={<ImportOutlined />}
              style={{
                color: "white",
                backgroundColor: storeInfo.storeConfig.cartButtonColor,
              }}
              type="primary"
              onClick={showModal}
              onClose={(e) => {
                setIsTMTModalVisible(false);
              }}
            >
              Import from Track My TCG
            </Button>
          </Tooltip>
          <Button
            icon={<ScanOutlined />}
            style={{
              color: "white",
              backgroundColor: storeInfo.storeConfig.cartButtonColor,
            }}
            type="primary"
            onClick={openCSVModal}
          >
            Import CSV
          </Button>
          <Button
            icon={<OrderedListOutlined />}
            style={{
              color: "white",
              backgroundColor: storeInfo.storeConfig.cartButtonColor,
            }}
            type="primary"
            onClick={openDeckModal}
          >
            Import Decklist
          </Button>
          {checkoutMode && (
            <>
              <Button
                type="primary"
                onClick={() =>
                  (window.location.href = "https://app.tcgsync.com/#/dashboard")
                }
              >
                Return to TCGSync
              </Button>
              <Button onClick={() => setCustomItemModalVisible(true)}>
                Add Custom Item
              </Button>
            </>
          )}
          <Select

          style={{ height: 30 }}
            defaultValue={selectedGame}
            onChange={handleGameSelection}
            placeholder="Select a game..."
            listHeight={500}
          >
            {gameSelectOptions}
          </Select>

          {!checkoutMode && (
            <Space direction="vertical" style={{ width: "100%" }}>
              {selectedGame === 1 || selectedGame === "Magic: The Gathering" ? (
                <Select
                  mode="multiple"
                  style={{ width: "100%" }}
                  placeholder="Select a Set/Expansion"
                  value={selectedExpansionFilters}
                  showSearch
                  filterOption={(input, option) =>
                    option.key.toLowerCase().includes(input.toLowerCase())
                  }
                  onChange={setSelectedExpansionFilters}
                >
                  {selectedGameSets
                    .sort((a, b) => a.name.localeCompare(b.name))
                    ?.map((expansion) => (
                      <Option key={expansion.name} value={expansion.groupId}>
                        <i
                          className={`ss ss-${expansion.abbreviation.toLowerCase()}`}
                        ></i>{" "}
                        {expansion.name}
                      </Option>
                    ))}
                </Select>
              ) : (
                <Select
                  mode="multiple"
                  style={{ width: "100%" }}
                  placeholder="Select a Set/Expansion"
                  value={selectedExpansionFilters}
                  showSearch
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                  onChange={setSelectedExpansionFilters}
                >
                  {selectedGameSets
                    .sort((a, b) => a.name.localeCompare(b.name))
                    ?.map((expansion) => (
                      <Option key={expansion.groupId} value={expansion.groupId}>
                        {expansion.name}
                      </Option>
                    ))}
                </Select>
              )}

              <Select
                mode="multiple"
                style={{ width: "100%" }}
                placeholder="Select Rarity Filter"
                value={selectedRarityFilters}
                onChange={handleRarityFilterChange}
              >
                {rarityFilterOptions.map((rarity) => (
                  <Option key={rarity} value={rarity}>
                    {rarity}
                  </Option>
                ))}
              </Select>
            </Space>
          )}

          <Input
            addonBefore={
              <>
                <Button
                  icon={<ShoppingCartOutlined />}
                  style={{
                    color: "white",
                    backgroundColor: storeInfo.storeConfig.cartButtonColor,
                  }}
                  type="primary"
                  onClick={handleCheckout}
                >
                  Cart
                </Button>
              </>
            }
            placeholder="Search products..."
            onChange={(value, _e, info) => {
              onSearch(value.target.value, _e, info);
            }}
            onSearch={(value, _e, info) => {
              onSearch(value, _e, info);
            }}
          />

          {!selectedGame && (
            <MostWantedCarousel
              wanted={storeInfo.storeConfig.mostWanted}
              config={storeInfo}
              cartItems={cartItems}
              setCartItems={setCartItems}
              iframe={false}
            ></MostWantedCarousel>
          )}
          {!checkoutMode && (
            <div className="product-count-info">
              <span className="product-count-text">
                Showing {sortedAndFilteredProducts.length} of {pCount} products
              </span>
            </div>
          )}
          <Modal
            title="Import from Track My TCG"
            visible={isTMTModalVisible}
            onCancel={() => setIsTMTModalVisible(false)} // Close modal when clicking outside or pressing escape
            footer={[
              <Button key="cancel" onClick={() => setIsTMTModalVisible(false)}>
                Cancel
              </Button>,
              <Button key="import" type="primary" onClick={handleOk}>
                Import
              </Button>,
            ]}
          >
            <Input
              placeholder="Enter TMT ID"
              value={tmtId}
              onChange={(e) => setTmtId(e.target.value)}
              style={{ marginBottom: "15px" }}
            />
            <Input.Password
              placeholder="Enter TMT PIN"
              value={tmtPin}
              onChange={(e) => setTmtPin(e.target.value)}
              visibilityToggle // Allow toggling password visibility
            />
          </Modal>

          <div className="pagination-container">
            {!checkoutMode && (
              <Pagination
                current={currentPage}
                pageSize={pageSize}
                total={pCount}
                onChange={handlePageChange}
                showSizeChanger={false}
              />
            )}
          </div>

          {sortedAndFilteredProducts.length === 0 ? (
            <div className="empty-notice">No products found.</div>
          ) : (
            <div className="product-grid">
              {checkoutMode
                ? sortedAndFilteredProducts.map((product, index) => (
                    <ProductsItems
                      key={product.productId}
                      product={product}
                      addToCart={addToCart}
                      removeCartItem={removeCartItem}
                      rules={storeInfo}
                      cartItems={cartItems}
                      searchQuery={searchQuery}
                      setCartItems={setCartItems}
                      exchangeRate={exchangeRate}
                      handleCheckout={handleCheckout}
                      selectedGame={selectedGame}
                    />
                  ))
                : sortedAndFilteredProducts.map((product, index) =>
                    mixMode == true ? (
                      <ProductItemMix
                        key={product.productId}
                        product={product}
                        addToCart={addToCart}
                        removeCartItem={removeCartItem}
                        rules={storeInfo}
                        cartItems={cartItems}
                        setCartItems={setCartItems}
                        exchangeRate={exchangeRate}
                        handleCheckout={handleCheckout}
                        selectedGame={selectedGame}
                      />
                    ) : (
                      <ProductItem
                        key={product.productId}
                        product={product}
                        addToCart={addToCart}
                        removeCartItem={removeCartItem}
                        rules={storeInfo}
                        cartItems={cartItems}
                        setCartItems={setCartItems}
                        exchangeRate={exchangeRate}
                        handleCheckout={handleCheckout}
                        selectedGame={selectedGame}
                      />
                    )
                  )}
            </div>
          )}

          <div className="pagination-container">
            {!checkoutMode && (
              <Pagination
                current={currentPage}
                pageSize={pageSize}
                total={pCount}
                onChange={handlePageChange}
                showSizeChanger={false}
              />
            )}
          </div>

          <Drawer
            title={checkoutVisible ? "Checkout" : "Cart"}
            placement="right"
            closable={true}
            onClose={() => {
              if (checkoutVisible) {
                setCheckoutVisible(false);
              } else {
                setCartVisible(false);
              }
            }}
            open={checkoutVisible || cartVisible}
            width={checkoutVisible ? "100%" : "100%"}
            maskClosable={false}
          >
            {checkoutMode && (
              <Input
                placeholder="Staff Member"
                value={staffMember}
                onChange={(e) => setStaffMember(e.target.value)}
                style={{ marginBottom: 10 }}
                required
              />
            )}

            {checkoutVisible ? (
              <div>
                <Radio.Group
                  value={fulfillmentMethod}
                  onChange={(e) => setFulfillmentMethod(e.target.value)}
                >
                  <Radio value="store-drop-in">Store Drop In</Radio>
                  {storeInfo?.buylistConfig?.enablePostalOrders && (
                    <Radio value="delivery">Postal</Radio>
                  )}
                </Radio.Group>
                <Input
                  placeholder="Full Name"
                  value={customerDetails.name}
                  onChange={(e) =>
                    setCustomerDetails({
                      ...customerDetails,
                      name: e.target.value,
                    })
                  }
                  style={{ marginBottom: 10 }}
                />
                <Input
                  placeholder="Email Address"
                  value={customerDetails.email}
                  onChange={(e) =>
                    setCustomerDetails({
                      ...customerDetails,
                      email: e.target.value,
                    })
                  }
                  style={{ marginBottom: 10 }}
                />
                <Input
                  placeholder="Phone Number"
                  value={customerDetails.phone}
                  onChange={(e) =>
                    setCustomerDetails({
                      ...customerDetails,
                      phone: e.target.value,
                    })
                  }
                  style={{ marginBottom: 10 }}
                />

                <Input
                  placeholder="Notes (Optional)"
                  value={customerDetails.notes}
                  onChange={(e) =>
                    setCustomerDetails({
                      ...customerDetails,
                      notes: e.target.value,
                    })
                  }
                  style={{ marginBottom: 10 }}
                />
                <div className="footer">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: storeInfo.storeConfig.checkoutMessage,
                    }}
                  ></div>
                </div>

                <Checkbox onChange={(e) => setConfirmed(e.target.checked)}>
                  I have read and agreed to the instructions.
                </Checkbox>
                <Button
                  type="primary"
                  style={{
                    backgroundColor: storeInfo.storeConfig.cartButtonColor,
                  }}
                  onClick={handlePlaceOrder}
                  disabled={buttonDisabled}
                >
                  Place Order
                </Button>
              </div>
            ) : (
              <div>
                <Table
                  dataSource={cartItems.map((item, index) => ({
                    key: index,
                    productName: item.product.name,
                    abbreviation: item.product.abbreviation,
                    expansionName: item.product.expansionName,
                    number: item.product.number,
                    quantity: item.quantity,
                    selectedType: item.selectedType,
                    condition: item.selectedCondition,
                    payment: item.type,
                    skus: item.skus,
                    price: item.price,
                  }))}
                  columns={columns}
                  size="small"
                  pagination={false} // Remove pagination on mobile
                />

                <div className="bulk-prices-section">
                  {storeInfo?.storeConfig?.bulkPrices &&
                    Object.keys(storeInfo.storeConfig.bulkPrices).map(
                      (gameId) => (
                        <div key={gameId}>
                          <ul>
                            {storeInfo.storeConfig.bulkPrices[gameId].map(
                              (bulkPrice, index) =>
                                bulkQuantities[gameId]?.[
                                  bulkPrice.condition
                                ] !== undefined &&
                                bulkQuantities[gameId]?.[
                                  bulkPrice.condition
                                ] !== 0 && (
                                  <li key={index}>
                                    We buy {bulkPrice.condition} at{" "}
                                    {getSymbolFromCurrency(
                                      storeInfo.storeConfig.storeCurrency
                                    )}
                                    {bulkPrice.price} per{" "}
                                    {bulkPrice.quantityLabel}
                                    <InputNumber
                                      value={
                                        bulkQuantities[gameId]?.[
                                          bulkPrice.condition
                                        ] || 0
                                      }
                                      readOnly={true}
                                    />
                                  </li>
                                )
                            )}
                          </ul>
                        </div>
                      )
                    )}
                </div>

                <div className="cart-total">
                  <h3>
                    Total Value (Cash):{" "}
                    {getSymbolFromCurrency(storeInfo.storeConfig.storeCurrency)}
                    {calculateTotalCartValue()}
                  </h3>
                  <h3>
                    Total Value (Credit):{" "}
                    {getSymbolFromCurrency(storeInfo.storeConfig.storeCurrency)}
                    {calculateTotalCreditCartValue()}
                  </h3>

                  <h3>
                    Total (Combined):{" "}
                    {getSymbolFromCurrency(storeInfo.storeConfig.storeCurrency)}
                    {calculateTotalCombinedCartValue()}
                  </h3>
                </div>

                <Button
                  type="primary"
                  style={{
                    backgroundColor: storeInfo.storeConfig.cartButtonColor,
                  }}
                  onClick={handleGoToCheckout}
                >
                  Go to Checkout
                </Button>
              </div>
            )}
          </Drawer>

          {orderPlaced && (
            <div style={resultStyle}>
              <Result
                status="success"
                title="Successfully Placed Your Order"
                subTitle={`Order number: ${
                  orderDetails?.orderId || "N/A"
                }. You will receive an email confirmation shortly.`}
                extra={[
                  <Button
                    type="primary"
                    key="console"
                    onClick={() => {
                      setOrderPlaced(false);
                      window.location.reload(true);
                    }}
                  >
                    Close
                  </Button>,
                ]}
              />
            </div>
          )}
        </>
        {/* Render the card scan modal */}
        <CardScanModal
          visible={cardScanModalVisible}
          onCancel={closeCardScanModal}
          setSearchQuery={setSearchQuery}
          cartItems={cartItems}
          setCartItems={setCartItems}
          rules={storeInfo}
          setSelectedGameProducts={setSelectedGameProducts}
        />
        <CSVModal
          visible={CSVModalVisible}
          onCancel={closeCSVModal}
          setSearchQuery={setSearchQuery}
          cartItems={cartItems}
          setCartItems={setCartItems}
          rules={storeInfo}
          setSelectedGameProducts={setSelectedGameProducts}
        />
        <DeckModal
          visible={DeckModalVisible}
          onCancel={closeDeckModal}
          setSearchQuery={setSearchQuery}
          cartItems={cartItems}
          setCartItems={setCartItems}
          rules={storeInfo}
          setSelectedGameProducts={setSelectedGameProducts}
        />
      </div>
      <Modal
        title="Add Custom Item"
        visible={isCustomItemModalVisible}
        onOk={addCustomItemToCart}
        onCancel={() => setCustomItemModalVisible(false)}
      >
        <Input
          placeholder="Item Name"
          value={customItemDetails.name}
          onChange={(e) => handleCustomItemDetailChange("name", e.target.value)}
        />
        <Input
          placeholder="Description"
          value={customItemDetails.description}
          onChange={(e) =>
            handleCustomItemDetailChange("description", e.target.value)
          }
          style={{ marginTop: 10 }}
        />
        <div style={{ display: "flex", alignItems: "center", marginTop: 10 }}>
          <InputNumber
            placeholder="Price"
            min={0}
            value={customItemDetails.price}
            onChange={(value) => handleCustomItemDetailChange("price", value)}
            style={{ flexGrow: 1 }}
          />
          <Button style={{ marginLeft: 10 }} onClick={handleGetPrice}>
            Get Price
          </Button>
        </div>
        <p>
          Please adjust the Item Configuration after adding it to the buylist.
          Also ensure you save once added.
        </p>
        <p>
          If you use Leads Online please click{" "}
          <a href="https://w4.leadsonline.com/Account/LogIn" target="_blank">
            Here
          </a>{" "}
          to record the sale
        </p>
        {/* Add inputs for any other details you wish to capture */}
      </Modal>
      ;
      <SelectItemsModal
        visible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        items={fetchedItems}
        onAddItems={handleAddItemsToCart}
      />
    </LoadingOverlay>
  );
};

export default MainDisplay;
