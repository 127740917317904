import React, { useEffect, useState } from "react";
import { Layout, ConfigProvider, Button } from "antd";
import en_US from "antd/lib/locale/en_US";
import chalk from "chalk";
import "./App.css";
import MainDisplay from "./components/MainDisplay";
import Deckbuilder from "./components/Deckbuilder"; // Import Deckbuilder component
import { getStoreData } from "./handler/APIService";
import axios from "axios";
import EventCalendar from "./components/EventCalender";
import Decklist from "./components/Decklist";
import OnboardingForm from "./components/OnboardingForm";
import MostWantedGrid from "./components/MostWanted";
const log = console.log;

const App = () => {
  const [storeInfo, setStoreInfo] = useState(null);
  const [redirectToNonIframed, setRedirectToNonIframed] = useState(false);
  const [exchangeRate, setExchangeRate] = useState(1);
  const [cMode, setCheckoutMode] = useState(false);
  const [deckbuilder, setDeckbuilder] = useState(false);
  const [onboarding, setOnboarding] = useState(false);
  const [mostwanted, setMostWanted] = useState(false);
  const [mixMode, setMixMode] = useState(false);

  const [events, setEvents] = useState(false);
  useEffect(() => {
    const fetchStoreData = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const storeId = urlParams.get("store_id");
      const checkoutMode = urlParams.get("checkoutMode");
      const deckbuilder = urlParams.get("deckbuilder");
      const onboarding = urlParams.get("onboarding");
      const events = urlParams.get("events");
      const mix = urlParams.get("mix");
      const mostwanted = urlParams.get("mostwanted");
      if (checkoutMode === "true") {
        setCheckoutMode(true);
      }
      if (deckbuilder === "true") {
        setDeckbuilder(true);
      }
      if (onboarding === "true") {
        setOnboarding(true);
      }
      if (events === "true") {
        setEvents(true);
      }
      if (mostwanted === "true") {
        setMostWanted(true);
      }
      if(mix === "true"){
        setMixMode(true);
      }
      if (storeId) {
        log(chalk.bold.cyan("Debug:"));
        log(chalk.green("Store_ID:"), storeId);

        try {
          const data = await getStoreData(storeId);
          log(chalk.green("Got Store Data:"), data);
          setStoreInfo(data.store);
          async function fetchExchangeRate() {
            if (exchangeRate === 1) {
              try {
                const response = await axios.get("https://server1.tcgs.xyz:4000/api/test/buylist_exchange?currencyCode=" + data.store.storeConfig.storeCurrency);
                const rate = response.data.data.usdToUserRate;
                const rate2 = response.data.data.eurToUserRate;
                setExchangeRate(rate);
              } catch (error) {
                console.error("Error fetching exchange rate:", error);
              }
            }
          }
          fetchExchangeRate();
        } catch (error) {
          console.error("Error fetching store data:", error);
        }
      }
    };

    fetchStoreData();

    if (window.self !== window.top && /Mobi|Android/i.test(navigator.userAgent)) {
      setRedirectToNonIframed(true);
    }
  }, []);

  const handleRedirect = () => {
    window.top.location.href = `https://buylist.tcgsync.com?store_id=${storeInfo.storeID}`;
  };

  return (
    <ConfigProvider locale={en_US}>
      {redirectToNonIframed && (
        <div className="mobile-redirect-container">
          <p>This page is best viewed on a non-mobile device.</p>
          <Button type="primary" onClick={handleRedirect}>
            Redirect to Mobile Site
          </Button>
        </div>
      )}
      {onboarding &&
        <OnboardingForm />
      }

      {storeInfo && !redirectToNonIframed && (
        <Layout style={{}}>
          <Layout.Content
            style={{
              margin: "0px 20px 0",
              padding: "20px",
              height: "100%",
              background: storeInfo.storeConfig.backgroundColor,
              justifyContent: "center",
            }}
          >
            {deckbuilder ? (
              <Decklist /> // Render Deckbuilder component if deckbuilder is true
            ) : (
              events ? (
                <EventCalendar />
              ) : (
                mostwanted ? (
                  <MostWantedGrid wanted={storeInfo.storeConfig.mostWanted} config={storeInfo} exchangeRate={exchangeRate} iframe={true} />
                ) : (
                  <MainDisplay storeInfo={storeInfo} mixMode={mixMode} log={log} checkoutMode={cMode} exchangeRate={exchangeRate} />
                )
              )
            )}


          </Layout.Content>
        </Layout>
      )}
    </ConfigProvider>
  );
};

export default App;
